import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";

const Footer =() =>{
    const navigate = useNavigate();
      const isMobileView = window.innerWidth <= 962;

  return (
    <Box minH="18rem" backgroundColor="#0071a4" padding="2rem">
      <Flex
        as="footer"
        align="center"
        // justify="space-between"
        wrap="wrap"
        // bg="gray.800"
        color="white"
        flexDirection={isMobileView?"column":"row"}
      >
        <Text
          fontSize="lg"
          fontWeight="bold"
          paddingRight="1.5rem"
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </Text>
        <Text
          fontSize="lg"
          fontWeight="bold"
          padding="0 1.5rem"
          onClick={() => {
            navigate("/about-us");
          }}
        >
          About Us
        </Text>
        <Text
          fontSize="lg"
          fontWeight="bold"
          padding="0 1.5rem"
          onClick={() => {
            navigate("/products");
          }}
        >
          Our Products
        </Text>
        <Text
          fontSize="lg"
          fontWeight="bold"
          padding="0 1.5rem"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Contact Us
        </Text>{" "}
      </Flex>
      <Box textAlign="left" color="#fff" my={8}>
        <Heading as="h2" fontSize="3xl" fontWeight="bold" mb={4}>
          Let’s work together!
        </Heading>
        <Text fontSize="lg" mb={4}>
          Just drop us a line -{" "}
          <Link color="#ffed47" href="mailto:info@henwood.com">
            info@henwood.com
          </Link>{" "}
          |{" "}
          <Link color="#ffed47" href="tel:+97142286595">
            +971 4 228 6595
          </Link>
        </Text>
      </Box>
      <Box display="flex" justifyContent="center">
        <Text fontSize="sm" color="gray.100">
          © 2024 Henwood Group. All Rights Reserved.
        </Text>
      </Box>
    </Box>
  );
}

export default Footer