import cart from "../../assets/icons/cart.png";
import plus from "../../assets/icons/plus.png";
import search from "../../assets/icons/search.png";
import subtract from "../../assets/icons/subtract.png";
import logo from "../../assets/icons/logo.png";
import vector from "../../assets/icons/vector.png";
import arrow from "../../assets/icons/arrow.png";
import share from "../../assets/icons/share.png";
import reset from "../../assets/icons/reset.png";
import imageshare from "../../assets/icons/imageshare.png";
import location from "../../assets/icons/location.png";
import facebook from "../../assets/icons/facebook.png";
import facebookh from "../../assets/icons/facebookh.png";
import instagram from "../../assets/icons/instagram.png";
import twitter from "../../assets/icons/twitter.png";
import twitterh from "../../assets/icons/twitterh.png";
import linkedinh from "../../assets/icons/linkedinh.png";
import user from "../../assets/icons/user.png";
import more from "../../assets/icons/more.png";


export const frontEndPoints = {
  default: "/",
  home: "/home",
  cart: "/cart",
  orderPlaced: "/order-placed",
  test: "/test",
  products: "/products",
  productDetail: "/products-detail/:id",
  aboutUs: "/about-us",
  contact: "/contact",
};

export const icons = {
  cart,
  plus,
  search,
  subtract,
  logo,
  arrow,
  vector,
  share,
  reset,
  imageshare,
  location,
  facebook,
  instagram,
  twitter,
  user,
  more,
  facebookh,
  linkedinh,
  twitterh,
};
