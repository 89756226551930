import React from "react";
import { Box, Image, Flex, Text, Grid, GridItem, Button } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import banner3 from "../../../../assets/banner/banner3.jpg";

const BannerCard = ({
  bannerData
}) => {

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
  autoplaySpeed: 3000,
   
  };
  return (
     <Box
      width="100%"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      paddingBottom={6}
      marginBottom={4}
      position="relative"
    >
      {/* <Slider {...settings}>
        {bannerData.map((item) => ( */}
      <Flex
        width="100%"
        direction={"column"}
        justifyContent="center"
        alignItems="center"
        backgroundColor="#F5F5F5"
        minH="28rem"
        maxH="30rem"
        borderTop={"1px solid #0053a0"}
        // onClick={() => {
        //   if (item?.target_link) {
        //     window.open(item?.target_link, "_blank");
        //   }
        // }}
      >
        <Image
          src={banner3}
          alt=""
          style={{ objectFit: "cover" }}
          width={"100%"}
          height={"28rem"}
        />
      </Flex>
      {/* ))}
      </Slider> */}
      <Flex
        position="absolute"
        top="0"
        height="95%"
        width="100%"
        background="linear-gradient(to right, #003ca3, rgba(255, 122, 89,0))"
      >
        <Box
          width="70%"
          padding="1rem 2.5rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text color="white" fontSize="2.5rem" fontWeight="600">
            About Us
          </Text>

          {/* <Button
            onClick={() => {
              // onSizeClick(pair);
            }}
            width="100px"
            backgroundColor="#0053a0"
            marginTop="1rem"
            color="white"
          >
            About US
          </Button> */}
        </Box>
      </Flex>
    </Box>
  );
};

export default BannerCard;
