import { ChakraProvider } from "@chakra-ui/react";
import { useEffect } from "react";
import { ThemeProvider } from "./components";
import Routes from "./routes";
import { Toaster } from "react-hot-toast";
function App() {
  useEffect(() => {
    if (!localStorage.getItem("shopping")) 
    {
      localStorage.setItem(
        "shopping",
        JSON.stringify({
          products: {},
        })
      );
    }
  
  });

  return (
    <ChakraProvider>
      <Toaster />

      <ThemeProvider>
        <Routes />
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
