import React, { useEffect, useState } from "react";
import { Header, ProductCard } from "../components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Text,
  Center,
  Flex,
  Button,
  Spinner,
  Heading,
  Card,
  CardBody,
  Image,
  Input,
  Textarea,
} from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { ApiUrl } from "../../../services/instance/index";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import "./index.css";
import { BannerCard } from "../components/bannerCard";
import { Footer, RenderIf, ScrollContainer } from "../../../components";
import { ArrowRightIcon, EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { icons } from "../../../utils";

const Contact = () => {
  const [brands, setBrands] = useState([]);
  const location = useLocation();
  const [searchData, setSearchData] = useState("");
  const [categories, setCategories] = useState([]);
  const [headerData, setHeaderData] = useState({
    brand: { value: "", label: "" },
    category: { value: "", label: "" },
    search: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoad, setIsLoad] = useState(false);
  const [addedProducts, setAddedProducts] = useState({});
  const [offset, setOffset] = useState(0);
    const [isDealer, setIsDeler] = useState(false);
  const [contacts, setContacts] = useState([]);

  const isMobileView = window.innerWidth <= 962;
useEffect(() => {
  const shopping = JSON.parse(localStorage.getItem("shopping"));
  setAddedProducts(shopping ? shopping.products : {});
}, []);
   useEffect(() => {
     //banners
     axios({
       method: "GET",
       url: `${ApiUrl}v1/contact-us`,
     })
       .then((res) => {
         setContacts(res.data.data);
       })
       .catch((err) => {
         console.log(err);
       });
   }, []);

 
  useEffect(() => {
    let params = {
      category: searchParams.get("category")
        ? categories.find((cat) => cat.value === searchParams.get("category"))
        : { value: "", label: "" },
      brand: searchParams.get("brand")
        ? brands.find((b) => b.value === searchParams.get("brand"))
        : { value: "", label: "" },
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    setHeaderData(params);
    setOffset(0);
  }, [searchParams]);

 
  return (
    <>
      <RenderIf isTrue={isLoad}>
        <Center
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            position: "fixed",
            zIndex: "1024",
            margin: isMobileView ? "0" : "0px -8rem",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </Center>
      </RenderIf>

      <Header
        categories={categories}
        brands={brands}
        // onBrandChange={(event) => handleHeaderData(event)}
        // onCategoryChange={(event) => handleHeaderData(event)}
        isMobileView={isMobileView}
        // onSearch={(event) => {
        //   handleSearch(event);
        // }}
        // onCloseSearch={() => {navigate('')}}
        // onCloseSearch={() => navigate('')}
        onSearchValue={(event) => setSearchData(event.target.value)}
        // onReset={() => resetHeaderData()}
        // selectedBrand={headerData?.brand}
        // selectedCategory={headerData?.category}
        selectedBrand={headerData?.brand?.value}
        selectedCategory={headerData?.category?.value}
        isCartNotify={Object.keys(addedProducts).length > 0}
        cartNotifyCount={
          Object.keys(addedProducts).length > 0 &&
          Object.keys(addedProducts).length
        }
        searchValue={searchData}
        onCartClick={() => navigate("/cart")}
        onShare={() => {
          console.log(location.pathname);
        }}
      />

      <ScrollContainer
        marginTop={isMobileView ? "4rem" : "10px"}
        backgroundColor="#4c8dca"
      >
        {/* <BannerCard bannerData={dummyBannerData || bannerData} /> */}
        <Box padding="1rem">
          <Card
            padding="1rem"
            display="flex"
            flexDirection={isMobileView ? "column" : "row"}
            borderRadius="15px"
          >
            {isDealer ? (
              <Box width="100%" padding="2rem 1rem">
                <Heading fontSize="2rem">Our Dealers</Heading>
                <Text fontSize="16px" color="gray" padding="1rem 0">
                  If you have any difficulty in sourcing a Henwood product or if
                  you have any comments, we shall be pleased to hear from you.
                </Text>
                <hr />
                <Text fontSize="16px" color="gray" padding="0.5rem 0">
                  <Text display="flex" alignItems="center">
                    <Image width="20px" height="20px" src={icons.location} />
                    <Text margin="0 0.5rem">Post:</Text>
                  </Text>
                  <Text padding="1rem 0">
                    Henwood General Trading CO. L.L.C. (Show Room) - 2 31a St -
                    Naif - Dubai)
                  </Text>
                </Text>
                <hr />
                {/* <Text fontSize="16px" color="gray" padding="0.5rem 0">
                  <Text display="flex" alignItems="center">
                    <Image width="20px" height="20px" src={icons.location} />
                    <Text margin="0 0.5rem">Post:</Text>
                  </Text>
                  <Text padding="1rem 0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,{" "}
                  </Text>
                </Text> */}
                <hr />

                {/* <Text fontSize="16px" color="gray" padding="0.5rem 0">
                  <Text display="flex" alignItems="center">
                    <Image width="20px" height="20px" src={icons.location} />
                    <Text margin="0 0.5rem">Post:</Text>
                  </Text>
                  <Text padding="1rem 0">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,{" "}
                  </Text>
                </Text> */}
                <hr />

                <Text fontSize="16px" color="gray" padding="0.5rem 0">
                  <Text display="flex" alignItems="center" padding="1rem 0">
                    Follow us
                  </Text>
                  <Text display="flex">
                    {" "}
                    <Button colorScheme="gray" variant="solid" margin="2px">
                      <Image src={icons.facebook} width="25px" height="25px" />
                    </Button>
                    <Button colorScheme="gray" variant="solid" margin="2px">
                      <Image src={icons.twitter} width="25px" height="25px" />
                    </Button>
                    <Button colorScheme="gray" variant="solid" margin="2px">
                      <Image src={icons.instagram} width="25px" height="25px" />
                    </Button>
                  </Text>
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    margin="1rem 0"
                    onClick={() => {
                      setIsDeler(false);
                    }}
                  >
                    Contact Us
                  </Button>
                </Text>
                <hr />
              </Box>
            ) : (
              <>
                <Box width={isMobileView ? "100%" : "50%"} padding="1rem">
                  <Heading fontSize="2rem">Contact</Heading>
                  <Text fontSize="16px" color="gray" padding="0.5rem 0">
                    If you have any difficulty in sourcing a Henwood product or
                    if you have any comments, we shall be pleased to hear from
                    you.
                  </Text>
                  <hr />
                  <Text fontSize="16px" color="gray" padding="0.5rem 0">
                    <Text display="flex" alignItems="center">
                      <EmailIcon width="20px" height="20px" color="#54595f" />
                      <Text margin="0 0.5rem"> E-mail:</Text>
                    </Text>
                    <Text padding="1rem 0">info@henwood.com</Text>
                  </Text>
                  <hr />
                  <Text fontSize="16px" color="gray" padding="0.5rem 0">
                    <Text display="flex" alignItems="center">
                      <PhoneIcon width="20px" height="20px" color="#54595f" />
                      <Text margin="0 0.5rem">Phone:</Text>
                    </Text>
                    <Text padding="1rem 0">+97142286595</Text>
                  </Text>
                  <hr />

                  <Text fontSize="16px" color="gray" padding="0.5rem 0">
                    <Text display="flex" alignItems="center">
                      <Image width="20px" height="20px" src={icons.location} />
                      <Text margin="0 0.5rem">Post:</Text>
                    </Text>
                    <Text padding="1rem 0">
                      Henwood General Trading CO. L.L.C. (Show Room) - 2 31a St
                      - Naif - Dubai, U.A.E.
                    </Text>
                  </Text>
                  <hr />

                  <Text fontSize="16px" color="gray" padding="0.5rem 0">
                    <Text display="flex" alignItems="center" padding="1rem 0">
                      Follow us
                    </Text>
                    <Text display="flex">
                      {" "}
                      <Button colorScheme="gray" variant="solid" margin="2px">
                        <Image
                          src={icons.facebook}
                          width="25px"
                          height="25px"
                        />
                      </Button>
                      <Button colorScheme="gray" variant="solid" margin="2px">
                        <Image src={icons.twitter} width="25px" height="25px" />
                      </Button>
                      <Button colorScheme="gray" variant="solid" margin="2px">
                        <Image
                          src={icons.instagram}
                          width="25px"
                          height="25px"
                        />
                      </Button>
                    </Text>
                    {/* <Button
                      colorScheme="blue"
                      variant="solid"
                      margin="1rem 0"
                      onClick={() => {
                        setIsDeler(true);
                      }}
                    >
                      Our Dealer
                    </Button> */}
                  </Text>
                  <hr />
                </Box>
                <Box
                  width={isMobileView ? "100%" : "50%"}
                  padding="1rem"
                  color="gray"
                >
                  <FormControl m={2}>
                    <FormLabel>Name</FormLabel>
                    <Input type="text" />
                  </FormControl>{" "}
                  <FormControl m={2}>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" />
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input type="number" />
                  </FormControl>
                  <FormControl m={2}>
                    <FormLabel>Message</FormLabel>
                    <Textarea type="text" />
                  </FormControl>
                  <Button colorScheme="blue" variant="solid" margin="1rem 0">
                    Send
                  </Button>
                </Box>
              </>
            )}
          </Card>
        </Box>
      </ScrollContainer>
      <Footer />
    </>
  );
};

export default Contact;
