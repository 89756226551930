import React, { useEffect, useRef, useState } from "react";
import { Box, Center, Flex, Text, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../../../services/instance";
import { icons } from "../../../../utils";

const ThankYou = () => {
  const navigate = useNavigate();
  const download_btn = useRef(null)

  const [ searchParams, setSearchParams ] = useSearchParams()
// const  params = searchParams.getAll();
const params = new URLSearchParams(window.location.search);

  useEffect(()=>{
   
    console.log("outside",params.get('auto_dowload'))
    if(params.get('auto_dowload')=="true")
    {
      console.log("inside",params.get('auto_dowload'))

      setTimeout(()=>{
        download_btn.current.click()
      },1000) 
    }
  },[searchParams])

  return (
    <div padding="10px">
      <Text
        p="5px"
        color="#000000"
        textAlign="center"
        marginTop="15px"
        fontWeight={"600px"}
        fontSize="20px"
        fontStyle={"SemiBold"}
        fontFamily="Roboto"
      >
        Thank You!
      </Text>

      <Center marginStart={"10px"} marginEnd={"10px"} marginTop={"15px"}>
        <Box
          bg="#0053a0"
          w="100%"
          p={3}
          h="29
                0px"
          borderRadius={"12px"}
        >
          <Center marginStart={"10px"} marginEnd={"10px"} marginTop={"60px"}>
            <Box>
              <Box bg="#FFFFFF" borderRadius={"50px"} w="90px" h="90px">
                <Center>
                  <Image marginTop={"25px"} src={icons.vector} />
                </Center>
              </Box>
            </Box>
          </Center>

          <Box>
            <Text
              color={"#FFFFFF"}
              textAlign="center"
              fontWeight="500px"
              marginTop={"50px"}
              fontSize={"18px"}
              fontFamily="Roboto"
            >
              Your request has been placed successfully!
            </Text>
            <Text
              color={"#FFFFFF"}
              textAlign="center"
              fontWeight="500px"
              fontSize={"18px"}
              fontFamily="Roboto"
            >
              Your request No. is {localStorage.getItem("order_number")}
            </Text>
          </Box>
        </Box>
      </Center>

      <div padding="5%">
        <Text
          textAlign="center"
          fontFamily="Roboto"
          marginTop={"20px"}
          fontSize={"14px"}
          color="#767676"
        >
          {" "}
          Delivery will be informed by SMS on your{" "}
        </Text>

        <Text
          textAlign="center"
          fontFamily="Roboto"
          fontSize={"14px"}
          color="#767676"
        >
          phone number
        </Text>
      </div>
      <Flex justifyContent={"center"}>
        <div>
          <a
            href={`${ApiUrl}v1/download-order/${localStorage.getItem(
              "receipt_id"
            )}`}
            download
          >
            <Button
              ref={download_btn}
              marginTop={"20px"}
              borderRadius={"2px solid blue"}
              color="#0053a0"
              fontSize={"14px"}
              fontWeight="600px"
              fontFamily="Roboto"
            >
              Download{" "}
            </Button>
          </a>
        </div>
      </Flex>

      <Flex justifyContent={"center"}>
        <div>
          <Button
            marginTop={"20px"}
            borderRadius={"2px solid blue"}
            color="#0053a0"
            fontSize={"14px"}
            fontWeight="600px"
            fontFamily="Roboto"
            onClick={() => {
              // localStorage.removeItem("order_id");
              // localStorage.removeItem("summary");
              // localStorage.setItem(
              //   "shopping",
              //   JSON.stringify({ products: {} })
              // );
              navigate("/home");
            }}
          >
            Back to Home{" "}
          </Button>
        </div>
      </Flex>
    </div>
  );
};

export default ThankYou;
