import React, { useState, useRef } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  Spacer,
  Text
} from "@chakra-ui/react";
import "./header.css";
import logo from "../../../../assets/images/logo.png";
import { RWebShare } from "react-web-share";
import { useEffect } from "react";
import { Button, Input, SelectBox } from "../../../../components";
import { icons } from "../../../../utils";
import { EmailIcon, PhoneIcon, RepeatIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";


const Header = ({
  brands,
  categories,
  subCategories,
  selectedBrand,
  selectedCategory,
  selectedSubCategory,
  searchValue,
  onBrandChange,
  onCategoryChange,
  onSubCategoryChange,
  onReset,
  isCartNotify,
  cartNotifyCount,
  onSearch,
  onCartClick,
  onSearchValue,
  onCloseSearch,
  setSelectedCategory,
  setSelectedBrand,
}) => {
  const [isInputVisible, setInputVisible] = useState(false);
  const [urlPath, setUrlPath] = useState("home");
  const navigate = useNavigate();
  const isMobileView = window.innerWidth <= 962;
  const [showMenu, setShowMenu] = useState(false);

  const searchField = useRef(null);
  console.log("searchField", searchField);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(searchValue);
      event.preventDefault();
      event.target.blur();
    }
  };
  useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname === "/") setUrlPath("home");
    else if (window.location.pathname === "/about-us") setUrlPath("about-us");
    else if (window.location.pathname === "/contact") setUrlPath("contact");
    else if (window.location.pathname === "/products") setUrlPath("products");
    else setUrlPath("");
  }, [window.location.pathname]);
  return (
    <Container
      // h={"145px"}
      position={isMobileView ? "fixed" : "sticky"}
      maxW={"100%"}
      top={0}
      zIndex={1024}
      backgroundColor={"#ffffff"}
      margin={0}
      padding={ "0"}
    >
      
      <Flex
        w="100%"
        justifyContent={"space-between"}
        flexDirection="column"
        pt={2.5}
      >
        <Flex
          w="100%"
          justifyContent={"space-between"}
          flexDirection="row"
          pt={2.5}
        >
          {!isMobileView && (
            <Center>
              <Flex
                justifyContent={"space-between"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  {" "}
                  <Image src={logo} width={isMobileView ? "3rem" : "6rem"} />
                </Box>
                {/* <Text>HENWOOD</Text> */}
              </Flex>
            </Center>
          )}
          {!isMobileView && (
            <Center>
              <Box
                padding="0 1rem"
                borderRadius="15px"
                backgroundColor="#0071a4"
                color="#fff"
              >
                <Flex
                  justifyContent={"space-between"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Text display="flex" alignItems="center">
                    {" "}
                    <EmailIcon
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                    <Text padding="1rem 0">info@henwood.com</Text>
                  </Text>
                  <Text display="flex" alignItems="center">
                    {" "}
                    <PhoneIcon
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                    <Text padding="1rem 0">+97142286595</Text>
                  </Text>
                  <Text display="flex" alignItems="center">
                    <Image
                      src={icons.facebookh}
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                  </Text>{" "}
                  <Text display="flex" alignItems="center">
                    <Image
                      src={icons.twitterh}
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                  </Text>{" "}
                  <Text display="flex" alignItems="center">
                    <Image
                      src={icons.linkedinh}
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                  </Text>{" "}
                </Flex>{" "}
              </Box>
            </Center>
          )}
        </Flex>
        <Flex
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
          backgroundColor={isMobileView ? "#d6eef8" : "#94bfd2"}
          marginTop={isMobileView ? "0" : "1rem"}
          lineHeight="45px"
          padding={isMobileView ? "0" : "0.5rem"}
          marginBottom="1rem"
        >
          {isMobileView ? (
            <>
              <span>
                <Image src={logo} width={isMobileView ? "3rem" : "6rem"} />
              </span>
              {showMenu && (
                <Flex
                  justifyContent={"space-evenly"}
                  alignContent={"center"}
                  alignItems={"center"}
                  flexFlow={"column"}
                  minH={"5rem"}
                  position={"absolute"}
                  right={"2.5rem"}
                  top={"4rem"}
                  backgroundColor={"#d4d4d4"}
                  padding={"1rem"}
                  zIndex="1"
                >
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "home"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/`);
                    }}
                  >
                    Home
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "about-us"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/about-us`);
                    }}
                  >
                    About US
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "products"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/products`);
                    }}
                  >
                    Our Products
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "contact"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/contact`);
                    }}
                  >
                    Contact
                  </Text>
                </Flex>
              )}
            </>
          ) : (
            <>
              <Center></Center>
              <Center>
                <Flex
                  justifyContent={"space-between"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "home"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/`);
                    }}
                  >
                    Home
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "about-us"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/about-us`);
                    }}
                  >
                    About US
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "products"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/products`);
                    }}
                  >
                    Our Products
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "contact"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/contact`);
                    }}
                  >
                    Contact
                  </Text>
                </Flex>
              </Center>
            </>
          )}
          <Center padding="0 0.5rem">
            <span onClick={() => onCartClick()}>
              <Button
                background="white"
                w="26px"
                isNotify={isCartNotify}
                notifyCount={cartNotifyCount}
                border={"none"}
              >
                <Image ml="0px" className="heading" src={icons.cart} />
              </Button>
            </span>
            <span
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              {isMobileView && (
                <Button background="white" w="26px" border={"none"}>
                  <Image ml="0px" width={5} height={5} src={icons.more} />
                </Button>
              )}
            </span>
          </Center>
        </Flex>
      </Flex>
      <Flex w="100%" justifyContent={"space-between"}>
        <Center
          marginRight="2px"
          backgroundColor={"#fff"}
          borderRadius={5}
          border="1px solid #c8c8c8"
          w={"31%"}
        >
          <SelectBox
            options={brands}
            name={"brand"}
            value={selectedBrand}
            onChange={(event) => {
              onBrandChange(event);
              console.log("event.target.value.value", event.target.value.value);
              setSelectedBrand(event.target.value.value);
            }}
            position={{ left: 0 }}
            fontFamily={"Roboto"}
            placeholder="Brands"
          />
        </Center>

        <Spacer />
        <Center
          marginRight="2px"
          backgroundColor={"#fff"}
          borderRadius={5}
          w={"31%"}
          border="1px solid #c8c8c8"
        >
          <SelectBox
            options={categories}
            value={selectedCategory}
            name={"category"}
            position={{ left: 0 }}
            onChange={(event) => {
              onCategoryChange(event);
              console.log("event.target.value.value", event.target.value.value);

              setSelectedCategory(event.target.value.value);
            }}
            fontFamily={"Roboto"}
            placeholder="Category"
          />
        </Center>
        <Spacer />
        <Center
          marginRight="2px"
          backgroundColor={"#fff"}
          borderRadius={5}
          w={"31%"}
          border="1px solid #c8c8c8"
        >
          <SelectBox
            options={subCategories}
            name={"subcategory"}
            value={selectedSubCategory}
            onChange={(event) => {
              onSubCategoryChange(event);
            }}
            position={{ left: 0 }}
            fontFamily={"Roboto"}
            placeholder="SubCategory"
          />
        </Center>
        <Center w={"6%"} marginLeft="4px">
          <Button
            onClick={() => {
              onReset();
            }}
            w={"2rem"}
            padding={"5px"}
          >
            <RepeatIcon />
          </Button>
        </Center>

        {/* <Center>
          <RWebShare
            data={{
              text: "you can find here your shoes",
              url: `${window.location.href}`,
              title: "Bootwala",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <Image
              ml="2px"
              w="20px"
              h="20px"
              className="heading"
              src={icons.share}
            />
          </RWebShare>
        </Center> */}
      </Flex>
    </Container>
  );
};

export default Header;
