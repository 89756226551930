import React from "react";
import { Button as Btn, Box, Center, Text } from "@chakra-ui/react";
import { RenderIf } from "../renderIf";

const Button = ({
  children,
  isNotify,
  notifyCount,
  icon,
  onClick,

  ...props
}) => {
  return (
    <Box position="relative">
      <Btn
        background={"#0053a0"}
        {...(!icon ?? {
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        })}
        padding="5px"
        color="#fff"
        marginLeft={"10px"}
        marginRight={"10px"}
        width={icon ? "25px" : "100px"}
        height={icon ? "25px" : "30px"}
        {...(icon ? { borderRadius: "4px", padding: "0", minW: "25px" } : {})}

        _hover={{
          opacity: 1,
          background: "#0053a0",
       
        }}
        {...(Button ? { borderRadius: "4px", padding: "0", minW: "20px" } : {})}
        {...(isNotify ? { border: "2px solid var(--yellow)" } : {})}
        onClick={onClick}
        {...props}
        _active={{
          background: "#0053a0",
        }}
      >
        {children}
      </Btn>
      <RenderIf isTrue={isNotify} >
        <Center
          width="20px"
          height="20px"
          borderRadius="50%"
          position="absolute"
          right="3px"
          top="-6px"
          background="#FFAC4A"
          color="white"
          onClick={onClick}
        >
          <Text fontSize="xs" fontWeight="bold" >
            {notifyCount} 
          </Text>
        </Center>
      </RenderIf>
    </Box>
    
  );
};

export default Button;
