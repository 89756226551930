import React, { useEffect, useState } from "react";
import { Header, ProductCard, RelatedProducts } from "../components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Text,
  useDisclosure,
  Center,
  Flex,
  Button, Spinner, Heading
} from "@chakra-ui/react";
import { ApiUrl } from "../../../services/instance/index";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";
import "./index.css";
import { BannerCard } from "../components/bannerCard";
import { Footer, RenderIf, ScrollContainer } from "../../../components";

const Home = () => {
  const [brands, setBrands] = useState([]);
  const location = useLocation();
  const [searchData, setSearchData] = useState("");
  const [currentList, setCurrentList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [headerData, setHeaderData] = useState({
    brand: { value: "", label: "" },
    category: { value: "", label: "" },
    search: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [products, setProdcuts] = useState([]);
  const [productDetail, setProdcutDetail] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({
    numberOfQty: 1,
    comment: "",
  });
  const [bannerData, setBannerData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [isInfiniteLoader, setIsInfiniteLoader] = useState(false);
  const [isScrollToErr, setIsScrollTOErr] = useState(true);
  const [addedProducts, setAddedProducts] = useState({});
  const [offset, setOffset] = useState(0);
  const { id } = useParams();
  const isMobileView = window.innerWidth <= 962;

  useEffect(() => {
    const shopping = JSON.parse(localStorage.getItem("shopping"));
    setAddedProducts(shopping ? shopping.products : {});
  }, []);

  useEffect(() => {
    console.log(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    let sltdSzs = [];
    Object.entries(addedProducts).map(
      ([addedProductKey, addedProductValue], addedProductIndex) => {
        sltdSzs.push({
          size_id: addedProductKey,
          pcs: addedProductValue.pcs,
        });
      }
    );
    setSelectedSizes(sltdSzs);
  }, [addedProducts]);

  useEffect(() => {
    console.log(selectedSizes);
  }, [selectedSizes]);

  useEffect(() => {
    setSelectedProduct((prev) => {
      prev = { ...prev };
      prev.qty = modalData.numberOfQty;
      prev.desc = modalData.comment;
      return prev;
    });
  }, [modalData]);

  
  useEffect(() => {
    //banners
    axios({
      method: "GET",
      url: `${ApiUrl}v1/get-banners`,
    })
      .then((res) => {
        console.log(res);
        setBannerData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    //Brands
    axios({
      method: "GET",
      url: `${ApiUrl}v1/brands`,
    })
      .then((res) => {
        let copiedBrands = [];
        res.data.data.forEach((brand) => {
          copiedBrands.push({
            value: brand,
            label: brand.split("_").join(" "),
          });
        });
        setBrands(copiedBrands);
      })
      .catch((err) => {
        console.log(err);
      });
    axios({
      method: "GET",
      url: `${ApiUrl}v1/categories`,
    })
      .then((res) => {
        let copiedCategories = [];
        res.data.data.forEach((category) => {
          copiedCategories.push({
            value: category,
            label: category.split("_").join(" "),
          });
        });
        setCategories(copiedCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleHeaderData = (event) => {
    let params = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      category: searchParams.get("category")
        ? searchParams.get("category")
        : "",
      // search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params[event.target.name] = ["brand", "category"].includes(
      event.target.name
    )
      ? event.target.value.value
      : event.target.value;
    // setHeaderData(params)
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

  const handleSearch = (event) => {
    //  document.getElementById("brand").value=""
    let params = {
      // brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      // category: searchParams.get("category")
      //   ? searchParams.get("category")
      //   : "",
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params.search = event;
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

  const resetHeaderData = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (Input) => (Input.value = "")
    );
    setSearchData("");
    navigate({
      pathname: "",
      search: createSearchParams({
        ...{},
      }).toString(),
    });
  };

  const getPriceText = (salePrice, offerPrice, discount, discountPrice) => {
    if (parseInt(offerPrice) > 0) {
      return (
        <Box w="100%">
          <Flex w="100%" justifyContent="center">
            <Text fontFamily={"Roboto"}>₹ {offerPrice}</Text>
          </Flex>
          {discountPrice > 0 && (
            <Text fontFamily={"Roboto"} fontSize="small">
              <span
                style={{
                  textDecoration: "line-through",
                  color: "rgba(0,0,0,0.5)",
                }}
              >
                {salePrice}
              </span>
              <span style={{ color: "var(--yellow)" }}> {discount} off</span>
            </Text>
          )}
        </Box>
      );
    } else {
      return <Text fontFamily={"Roboto"}>₹ {salePrice}</Text>;
    }
  };

  const getDefaultMoq = (moq) => {
    if (typeof moq === "string" && moq.trim() === "") {
      return 1;
    } else if (moq === 0) {
      return 1;
    } else {
      return moq;
    }
  };

  const handleAddAndProduct = (data) => {
    console.log(data);
    let duplicateAddedProducts = { ...addedProducts };
    if (!duplicateAddedProducts[data.size_id]) {
      duplicateAddedProducts[data.size_id] = {
        ...data,
        qty: 1,
        pcs: getDefaultMoq(data.quantityInput),
        moq: getDefaultMoq(data.quantityInput),
      };
    } else {
      duplicateAddedProducts[data.size_id].qty =
        duplicateAddedProducts[data.size_id].qty + 1;
      duplicateAddedProducts[data.size_id].pcs =
        duplicateAddedProducts[data.size_id].qty *
        duplicateAddedProducts[data.size_id].moq;
    }
    console.log(duplicateAddedProducts);
    setAddedProducts(duplicateAddedProducts);
    let shopping = JSON.parse(localStorage.getItem("shopping"));
    shopping.products = duplicateAddedProducts;
    localStorage.setItem("shopping", JSON.stringify(shopping));
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (id) {
      setIsLoad(true);
      axios({
        method: "GET",
        url: `${ApiUrl}v1/products?code=${id}`,
      })
        .then((res) => {
          setIsInfiniteLoader(false);
          setIsScrollTOErr(false);
          setProdcutDetail(res.data.data[0]);
          console.log("products", res.data.data[0]);
                setIsLoad(false);

          // setCurrentList(res.data.data);
          // setOffset((prev) => prev + 1);
        })
        .catch((err) => {
          setIsScrollTOErr(true);
          setIsInfiniteLoader(true);
                          setIsLoad(false);

          console.log(err);
        });
    }
  }, [id]);

      const [quantityValues, setQuantityValues] = useState([]);
   const handleQtyChange = (pairId, quantity) => {
     setQuantityValues((prevQuantityValues) => ({
       ...prevQuantityValues,
       [pairId]: quantity,
     }));
   };

  return (
    <>
      <RenderIf isTrue={isLoad}>
        <Center
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            position: "fixed",
            zIndex: "1024",
            margin: isMobileView ? "0" : "0px -8rem",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </Center>
      </RenderIf>

      <Header
        categories={categories}
        brands={brands}
        onBrandChange={(event) => handleHeaderData(event)}
        onCategoryChange={(event) => handleHeaderData(event)}
        onSearch={(event) => {
          handleSearch(event);
        }}
        // onCloseSearch={() => {navigate('')}}
        // onCloseSearch={() => navigate('')}
        onSearchValue={(event) => setSearchData(event.target.value)}
        onReset={() => resetHeaderData()}
        // selectedBrand={headerData?.brand}
        // selectedCategory={headerData?.category}
        selectedBrand={headerData?.brand?.value}
        selectedCategory={headerData?.category?.value}
        isCartNotify={Object.keys(addedProducts).length > 0}
        cartNotifyCount={
          Object.keys(addedProducts).length > 0 &&
          Object.keys(addedProducts).length
        }
        searchValue={searchData}
        onCartClick={() => navigate("/cart")}
        onShare={() => {
          console.log(location.pathname);
        }}
      />

      <ScrollContainer marginTop={isMobileView ? "4rem" : "10px"}>
        {/* <InfiniteScroll
          dataLength={products.length}
          next={() => handleScroll()}
          hasMore={true}
          loader={""}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>There is no more options available</b>
            </p>
          }
        > */}
        <BannerCard bannerData={bannerData} />
        {/* {products?.map((product, productIndex) => ( */}
        <ProductCard
          key={1}
          image={productDetail?.image}
          color={productDetail?.color}
          moq={getDefaultMoq(productDetail?.moq)}
          brand={productDetail?.brand}
          name={productDetail?.name}
          // variations={variations}
          handleQtyChange={handleQtyChange}
          quantityValues={quantityValues}
          priceText={getPriceText(
            productDetail?.salePrice,
            productDetail?.offerPrice,
            productDetail?.discount,
            productDetail?.discountPrice
          )}
          sales_unit={productDetail?.sales_unit}
          box_pack={productDetail?.box_pack}
          cartoon_pack={productDetail?.cartoon_pack}
          description={productDetail?.description}
          category={productDetail?.category?.[0]}
          sizes={productDetail?.sizes}
          onSizeClick={(data) => {
            handleAddAndProduct({
              ...productDetail,
              size_id: data["_id"],
              size: data.size,
              quantityInput: data.quantity,
            });
          }}
          selectedSizes={selectedSizes}
          code={productDetail?.code}
          isMobileView={isMobileView}
        />
        {/* ))} */}
        {/* </InfiniteScroll> */}
        <hr />
        {/* <Box>
          <Heading padding="1rem 2rem" fontSize="2rem">
            Related products
          </Heading>

          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(16rem, 1fr))"
          >
            {products?.map((product, productIndex) => (
              <RelatedProducts
                key={productIndex}
                image={product?.image}
                color={product?.color}
                moq={getDefaultMoq(product?.moq)}
                brand={product?.brand}
                name={product?.name}
                priceText={getPriceText(
                  product?.salePrice,
                  product?.offerPrice,
                  product?.discount,
                  product?.discountPrice
                )}
                sizes={product?.sizes}
                onSizeClick={(data, quantityInput) => {
                  handleAddAndProduct({
                    ...product,
                    size_id: data["_id"],
                    size: data.size,
                    quantityInput: quantityInput,
                  });
                }}
                selectedSizes={selectedSizes}
                code={product?.code}
                isMobileView={isMobileView}
              />
            ))}
          </Box>
        </Box> */}
      </ScrollContainer>
      <Footer />
    </>
  );
};

export default Home;
