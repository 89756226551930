import React, { useEffect, useState } from "react";
import { Header, ProductCard } from "../components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Text,
  useDisclosure,
  Center,
  Flex,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { ApiUrl } from "../../../services/instance/index";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import "./index.css";
import { BannerCard } from "../components/bannerCard";
import { RenderIf, ScrollContainer } from "../../../components";
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from "@ajna/pagination";

const Home = () => {
  const [brands, setBrands] = useState([]);
  const location = useLocation();
  const [searchData, setSearchData] = useState("");
  const [currentList, setCurrentList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  // const [currentPage,setCurrentPage] = useState(1)
  const [headerData, setHeaderData] = useState({
    brand: { value: "", label: "" },
    category: { value: "", label: "" },
    subcategory: { value: "", label: "" },

    search: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [products, setProdcuts] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState({
    numberOfQty: 1,
    comment: "",
  });
  const [bannerData, setBannerData] = useState([]);
  const [total, setTotal] = useState();
  const [isLoad, setIsLoad] = useState(false);
  const [isProduct, setIsproduct] = useState(false);
  const [isInfiniteLoader, setIsInfiniteLoader] = useState(false);
  const [isScrollToErr, setIsScrollTOErr] = useState(true);
  const [addedProducts, setAddedProducts] = useState({});
  const [offset, setOffset] = useState(0);
  const isMobileView = window.innerWidth <= 962;

  const {
    pages,
    pagesCount,
    // offset,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: total,

    initialState: {
      pageSize: 16,
      isDisabled: false,
      currentPage: offset + 1,
    },
  });
  useEffect(() => {
    const shopping = JSON.parse(localStorage.getItem("shopping"));
    setAddedProducts(shopping ? shopping.products : {});
    setCurrentPage(1);
    setOffset(0);
  }, []);

  useEffect(() => {
    console.log(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    let sltdSzs = [];
    Object.entries(addedProducts).map(
      ([addedProductKey, addedProductValue], addedProductIndex) => {
        sltdSzs.push({
          size_id: addedProductKey,
          pcs: addedProductValue.pcs,
        });
      }
    );
    setSelectedSizes(sltdSzs);
  }, [addedProducts]);

  useEffect(() => {
    console.log(selectedSizes);
  }, [selectedSizes]);

  useEffect(() => {
    setSelectedProduct((prev) => {
      prev = { ...prev };
      prev.qty = modalData.numberOfQty;
      prev.desc = modalData.comment;
      return prev;
    });
  }, [modalData]);
 const getSubcategory = () => {
   axios({
     method: "GET",
     url: `${ApiUrl}v1/subcategories`,
   })
     .then((res) => {
       let copiedCategories = [];
       res.data.data.forEach((category) => {
         copiedCategories.push({
           value: category.slug,
           label: category.name,
         });
       });
       setSubCategories(copiedCategories);
     })
     .catch((err) => {
       console.log(err);
     });
 };
  useEffect(() => {
    let params = {
      category: searchParams.get("category")
        ? categories.find((cat) => cat.value === searchParams.get("category"))
        : { value: "", label: "" },
      subcategory: searchParams.get("subcategory")
        ? subCategories.find(
            (cat) => cat.value === searchParams.get("subcategory")
          )
        : { value: "", label: "" },
      brand: searchParams.get("brand")
        ? brands.find((b) => b.value === searchParams.get("brand"))
        : { value: "", label: "" },
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };

    console.log("searchParams.get('brand')", searchParams.get("brand"));
    console.log("brands...............", brands);
    console.log("params", params);

    setHeaderData(params);
    setOffset(0);
  }, [searchParams, brands]);
 
  useEffect(() => {
    if (searchParams.get("brand") || selectedBrand) {
      axios({
        method: "GET",
        url: `${ApiUrl}v1/categories?brand=${
          searchParams.get("brand") || selectedBrand
        }`,
      })
        .then((res) => {
          let copiedCategories = [];
          res.data.data.forEach((category) => {
            copiedCategories.push({
              value: category.slug,
              label: category.name,
            });
          });
          setCategories(copiedCategories);
          getSubcategory();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchParams.get("brand"), selectedBrand]);
  useEffect(() => {
    if (searchParams.get("category") || selectedCategory) {
      axios({
        method: "GET",
        url: `${ApiUrl}v1/subcategories?category=${
          searchParams.get("category") || selectedCategory
        }`,
      })
        .then((res) => {
          let copiedCategories = [];
          res.data.data.forEach((category) => {
            copiedCategories.push({
              value: category.slug,
              label: category.name,
            });
          });
          setSubCategories(copiedCategories);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchParams.get("category"), selectedCategory]);

  useEffect(() => {
    // scrollToTop();
    setIsLoad(true);
        setIsInfiniteLoader(true);
    setIsScrollTOErr(true);

    // console.log("aaaaaaaaaaaaaaaaaaaa",
    //   searchParams.get("subcategory")
    //     ? `&subcategory=${searchParams.get("subcategory")}`
    //     : "not aaa"
    // );
    axios({
      method: "GET",
      url: `${ApiUrl}v1/products?limit=21${
        searchParams.get("brand") ? `&brand=${searchParams.get("brand")}` : ""
      }
      ${
        searchParams.get("category")
          ? `&category=${searchParams.get("category")}`
          : ""
      }
       ${
         searchParams.get("search")
           ? `&search=${searchParams.get("search")}`
           : ""
       }
       ${searchParams.get("code") ? `&code=${searchParams.get("code")}` : ""}
       ${searchParams.get("color") ? `&color=${searchParams.get("color")}` : ""}
       ${
         searchParams.get("size") ? `&size=${searchParams.get("size")}` : ""
       }&offset=${offset}      ${
        searchParams.get("subcategory")
          ? `&subcategory=${searchParams.get("subcategory")}`
          : ""
      }
  `,
    })
      .then((res) => {
        setIsLoad(false);
        setCurrentList(res.data.data);
                setIsInfiniteLoader(false);
         setIsScrollTOErr(false);

       if (res.data.data.length === 0) {
         setIsproduct(true);
         console.log("Data length is 0");
       } 
        if (offset == 0) {
          scrollToTop();
          setProdcuts(res.data.data);
        } else {
          setProdcuts([...products, ...res.data.data]);
        }
        console.log(res.data.total);
        setTotal(res.data.total);
      })
      .catch((err) => {
         setIsInfiniteLoader(true);
         setIsScrollTOErr(true);
        setIsLoad(false);

        console.log(err);
      });
  }, [searchParams, offset]);

  useEffect(() => {
    //banners
    axios({
      method: "GET",
      url: `${ApiUrl}v1/get-banners`,
    })
      .then((res) => {
        console.log(res);
        setBannerData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    //Brands
    axios({
      method: "GET",
      url: `${ApiUrl}v1/brands`,
    })
      .then((res) => {
        let copiedBrands = [];
        res.data.data.forEach((brand) => {
          copiedBrands.push({
            value: brand.slug,
            label: brand.name,
          });
        });
        setBrands(copiedBrands);
      })
      .catch((err) => {
        console.log(err);
      });
    axios({
      method: "GET",
      url: `${ApiUrl}v1/categories`,
    })
      .then((res) => {
        let copiedCategories = [];
        res.data.data.forEach((category) => {
          copiedCategories.push({
            value: category.slug,
            label: category.name,
          });
        });
        setCategories(copiedCategories);
      })
      .catch((err) => {
        console.log(err);
      });
   getSubcategory();
  }, []);

  const handleHeaderData = (event) => {
    console.log(
      "dataa",
      searchParams.get("brand"),
      event.target.name,
      event.target.value.value
    );
    let params = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      category: searchParams.get("category")
        ? searchParams.get("category")
        : "",
      subcategory: searchParams.get("subcategory")
        ? searchParams.get("subcategory")
        : "",
      // search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params[event.target.name] = ["brand", "category", "subcategory"].includes(
      event.target.name
    )
      ? event.target.value.value
      : event.target.value;

    if (event.target.name == "brand") {
      setSelectedBrand(event.target.value.value);
       params.category = "";
       params.subcategory = "";
    }
    if (event.target.name == "category") {
      setSelectedCategory(event.target.value.value);
          params.subcategory = "";

    }
    // setHeaderData(params)
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

  const handleSearch = (event) => {
    //  document.getElementById("brand").value=""
    let params = {
      // brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      // category: searchParams.get("category")
      //   ? searchParams.get("category")
      //   : "",
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params.search = event;
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

  const resetHeaderData = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (Input) => (Input.value = "")
    );
    setSearchData("");
    navigate({
      pathname: "",
      search: createSearchParams({
        ...{},
      }).toString(),
    });
  };

  const getPriceText = (salePrice, offerPrice, discount, discountPrice) => {
    if (parseInt(offerPrice) > 0) {
      return (
        <Box w="100%">
          <Flex w="100%" justifyContent="center">
            <Text fontFamily={"Roboto"}>₹ {offerPrice}</Text>
          </Flex>
          {discountPrice > 0 && (
            <Text fontFamily={"Roboto"} fontSize="small">
              <span
                style={{
                  textDecoration: "line-through",
                  color: "rgba(0,0,0,0.5)",
                }}
              >
                {salePrice}
              </span>
              <span style={{ color: "var(--yellow)" }}> {discount} off</span>
            </Text>
          )}
        </Box>
      );
    } else {
      return <Text fontFamily={"Roboto"}>₹ {salePrice}</Text>;
    }
  };

  const getDefaultMoq = (moq) => {
    if (typeof moq === "string" && moq.trim() === "") {
      return 1;
    } else if (moq === 0) {
      return 1;
    } else {
      return moq;
    }
  };

  const handleAddAndProduct = (data) => {
    console.log(data);
    let duplicateAddedProducts = { ...addedProducts };
    if (!duplicateAddedProducts[data.size_id]) {
      duplicateAddedProducts[data.size_id] = {
        ...data,
        qty: 1,
        pcs: getDefaultMoq(data.moq),
        moq: getDefaultMoq(data.moq),
      };
    } else {
      duplicateAddedProducts[data.size_id].qty =
        duplicateAddedProducts[data.size_id].qty + 1;
      duplicateAddedProducts[data.size_id].pcs =
        duplicateAddedProducts[data.size_id].qty *
        duplicateAddedProducts[data.size_id].moq;
    }
    console.log(duplicateAddedProducts);
    setAddedProducts(duplicateAddedProducts);
    let shopping = JSON.parse(localStorage.getItem("shopping"));
    shopping.products = duplicateAddedProducts;
    localStorage.setItem("shopping", JSON.stringify(shopping));
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <RenderIf isTrue={isLoad}>
        <Center
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            position: "fixed",
            zIndex: "1024",
            margin: isMobileView ? "0" : "0px -8rem",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </Center>
      </RenderIf>

      <Header
        categories={categories}
        subCategories={subCategories}
        brands={brands}
        onBrandChange={(event) => handleHeaderData(event)}
        onCategoryChange={(event) => handleHeaderData(event)}
        onSubCategoryChange={(event) => handleHeaderData(event)}
        setSelectedBrand={setSelectedBrand}
        setSelectedCategory={setSelectedCategory}
        onSearch={(event) => {
          handleSearch(event);
        }}
        // onCloseSearch={() => {navigate('')}}
        // onCloseSearch={() => navigate('')}
        onSearchValue={(event) => setSearchData(event.target.value)}
        onReset={() => resetHeaderData()}
        // selectedBrand={headerData?.brand}
        // selectedCategory={headerData?.category}
        selectedBrand={headerData?.brand?.value}
        selectedSubCategory={headerData?.subcategory?.value}
        selectedCategory={headerData?.category?.value}
        isCartNotify={Object.keys(addedProducts).length > 0}
        cartNotifyCount={
          Object.keys(addedProducts).length > 0 &&
          Object.keys(addedProducts).length
        }
        searchValue={searchData}
        onCartClick={() => navigate("/cart")}
        onShare={() => {
          console.log(location.pathname);
        }}
      />

      <ScrollContainer marginTop={isMobileView ? "8rem" : "10px"}>
        <InfiniteScroll
          dataLength={products.length}
          // next={() => handleScroll()}
          scrollThreshold={isMobileView ? "200px" : "180px"}
          next={() => {
            console.log("offset + 1", offset + 1);
            setOffset(offset + 1);
          }}
          hasMore={isProduct == true ? false : true}
          loader={""}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>There is no more options available</b>
            </p>
          }
        >
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(18rem, 1fr))"
            overflowX="hidden"
          >
            {products?.map((product, productIndex) => (
              <ProductCard
                key={productIndex}
                image={product?.image}
                color={product?.color}
                moq={getDefaultMoq(product?.moq)}
                brand={product?.brand}
                name={product?.name}
                priceText={getPriceText(
                  product?.salePrice,
                  product?.offerPrice,
                  product?.discount,
                  product?.discountPrice
                )}
                sales_unit={product?.sales_unit}
                box_pack={product?.box_pack}
                cartoon_pack={product?.cartoon_pack}
                sizes={product?.sizes}
                onSizeClick={(data) => {
                  handleAddAndProduct({
                    ...product,
                    size_id: data["_id"],
                    size: data.size,
                  });
                }}
                selectedSizes={selectedSizes}
                code={product?.code}
                isMobileView={isMobileView}
              />
            ))}
          </Box>
        </InfiniteScroll>
        {isProduct == true ? (
          <>
            {isLoad == false && (
              <Text w="100%" fontFamily={"Roboto"} textAlign={"center"} p={10}>
                Opps! products are not available
              </Text>
            )}
          </>
        ) : (
          ""
        )}
        {/* <Box padding={isMobileView ? "0" : "0 3rem"}>
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            isDisabled={isDisabled}
            onPageChange={(nextPage) => {
              setCurrentPage(nextPage);
              setOffset(nextPage - 1);
              console.log("request new data with ->", nextPage);
            }}
          >
            <PaginationContainer
              align="center"
              justify="space-between"
              p={4}
              w="full"
            >
              <PaginationPrevious
                _hover={{
                  bg: "blue.400",
                }}
                bg="blue.300"
                onClick={() =>
                  console.log(
                    "Im executing my own function along with Previous component functionality"
                  )
                }
              >
                <Text>Previous</Text>
              </PaginationPrevious>
              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    onClick={() =>
                      console.log(
                        "Im executing my own function along with Separator component functionality"
                      )
                    }
                    bg="blue.300"
                    fontSize="sm"
                    w={7}
                    jumpSize={11}
                  />
                }
              >
                {isMobileView && pages.length > 5 ? (
                  <></>
                ) : (
                  <>
                    {pages.map((page) => (
                      <PaginationPage
                        w={7}
                        bg="blue.300"
                        key={`pagination_page_${page}`}
                        page={page}
                        onClick={() =>
                          console.log(
                            "Im executing my own function along with Page component functionality"
                          )
                        }
                        fontSize="sm"
                        _hover={{
                          bg: "green.300",
                        }}
                        _current={{
                          bg: "green.300",
                          fontSize: "sm",
                          w: 7,
                        }}
                      />
                    ))}
                  </>
                )}
              </PaginationPageGroup>
              <PaginationNext
                _hover={{
                  bg: "blue.400",
                }}
                bg="blue.300"
                onClick={() =>
                  console.log(
                    "Im executing my own function along with Next component functionality"
                  )
                }
              >
                <Text>Next</Text>
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Box> */}
        {/* <Box
          margin="3.5rem 0"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => {
              setOffset(offset + 1);
            }}
            isLoading={isLoad}
            loadingText="Loading more"
          >
            Show More
          </Button>
        </Box> */}
        <RenderIf isTrue={isInfiniteLoader}>
          <RenderIf isTrue={isScrollToErr}>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <Center>
                <Spinner />
              </Center>
            </span>
            <Center>
              {" "}
              <Text>Hang on,loading content</Text>
            </Center>
          </RenderIf>
        </RenderIf>
      </ScrollContainer>
    </>
  );
};

export default Home;
