import React from "react";
import { ProductCard } from "../layouts/home/components";
import { frontEndPoints } from "../utils";
import { AboutUs, Cart, Contact, Home, OrderPlaced, ProductList, Test } from "../layouts";
import { ProductDetail } from "../layouts/productDetail";

const routes = [
  {
    path: frontEndPoints.default,
    element: <Home />,
  },
  {
    path: frontEndPoints.home,
    element: <Home />,
  },
  {
    path: frontEndPoints.products,
    element: <ProductList />,
  },
  {
    path: frontEndPoints.productDetail,
    element: <ProductDetail />,
  },

  {
    path: frontEndPoints.cart,
    element: <Cart />,
  },
  {
    path: frontEndPoints.orderPlaced,
    element: <OrderPlaced />,
  },
  {
    path: frontEndPoints.test,
    element: <Test />,
  },
  {
    path: frontEndPoints.aboutUs,
    element: <AboutUs />,
  },
  {
    path: frontEndPoints.contact,
    element: <Contact />,
  },
];

export default routes;