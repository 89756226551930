import React from "react";
import { Box, Center } from "@chakra-ui/react";

const MobileView = ({ children }) => {
  return (
  <Center w="100%" h="100vh" alignItems="center" marginRight="50px">
      <Box width="100%" maxW={"480px"} height="100vh">
        {children}
      </Box>
    </Center>
  );
};

const WebView = ({ children }) => {
  return (
    <Box
      width="100%"
    //   maxW={"960px"} 
      marginX="auto"
    >
      {children}
    </Box>
  );
};

const ResponsiveView = ({ children }) => {
  const isMobileView = window.innerWidth <= 900;

  return isMobileView ? (
    <MobileView>{children}</MobileView>
  ) : (
    <WebView>{children}</WebView>
  );
};

export default ResponsiveView;
