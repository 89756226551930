import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Flex,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  TableContainer,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Button } from "../../../../components";
import { AddIcon } from "@chakra-ui/icons";
import bossilImg from "../../../../assets/images/bossil.jpg";
import bigfixImg from "../../../../assets/images/BIGFIX.png";
import dafkilitImg from "../../../../assets/images/DAFKILIT.png";
import furezaImg from "../../../../assets/images/FUREZA.png";
import krukerImg from "../../../../assets/images/KRUKER.png";
import seagullImg from "../../../../assets/images/SEAGULL.png";
import trustImg from "../../../../assets/images/TRUST.png";
import toast from "react-hot-toast";

const ProductCard = ({
  image,
  brand,
  name,
  sizes,
  onSizeClick,
  description,
  isMobileView,
  category,
  box_pack,
  cartoon_pack,
  sales_unit,
}) => {
  const [inputValues, setInputValues] = useState([]);

  const [placeholderImage, setPlaceholderImage] = useState();

  useEffect(() => {
    if (brand === "bossil") {
      setPlaceholderImage(bossilImg);
    } else if (brand === "big-fix") {
      setPlaceholderImage(bigfixImg);
    } else if (brand === "daf-kilit") {
      setPlaceholderImage(dafkilitImg);
    } else if (brand === "fuerza") {
      setPlaceholderImage(furezaImg);
    } else if (brand === "kruker") {
      setPlaceholderImage(krukerImg);
    } else if (brand === "seagull") {
      setPlaceholderImage(seagullImg);
    } else if (brand === "trust") {
      setPlaceholderImage(trustImg);
    }
  }, [brand]);

  const handleInputChange = (pairIndex, inputValue) => {
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[pairIndex] = {
        ...newInputValues[pairIndex],
        ...inputValue,
      };
      return newInputValues;
    });
  };

  const handleAddToCart = (pairIndex) => {
    onSizeClick(inputValues[pairIndex]);
    setInputValues((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[pairIndex] = {
        ...newInputValues[pairIndex],
        quantity: "",
      };
      return newInputValues;
    });
     toast.success("Product Added to Cart Successfully!", {
       position: "top-right",
     });
  };

  useEffect(() => {
    console.log(inputValues);
  }, [inputValues]);

  return (
    <Box
      width="100%"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      paddingBottom={6}
      padding={5}
    >
      <Flex
        justifyContent="flex-start"
        direction={`${isMobileView ? "column" : "row"}`}
        padding={2}
      >
        <Flex
          width={`${isMobileView ? "100%" : "35%"}`}
          direction="column"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#F5F5F5"
          borderTop="1px solid #0053a0"
          padding="0.5rem 2rem"
        >
          <Image
            src={image}
            alt=""
            width="65%"
            height="auto"
            minH={"155px"}
            maxH={"400px"}
            fallbackSrc={placeholderImage}
          />
        </Flex>
        <Flex
          direction="column"
          width={`${isMobileView ? "100%" : "55%"}`}
          justifyContent="flex-start"
          padding="0.5rem 2rem"
        >
          <Flex direction="column" justifyContent="space-around">
            <Text
              fontSize="22px"
              fontWeight="600"
              textOverflow="ellipsis"
              className="heading-font"
            >
              {name || "Sample Product Title"}
            </Text>
          </Flex>

          <Text fontSize="16px" fontWeight="500" color="gray" padding="1rem 0">
            {description}
          </Text>
          <hr style={{ borderColor: "#000" }} />
          <Text fontSize="16px" fontWeight="500" mt="4px" paddingTop="1rem">
            <b color="#000">Box Pack: </b>{" "}
            <span>{box_pack ? box_pack + "/box" : "N/A"} </span>
          </Text>
          <Text fontSize="16px" fontWeight="500" paddingTop="0.2rem">
            <b color="#000">Cartoon Pack:</b>{" "}
            <span>{cartoon_pack ? cartoon_pack + "/cartoon" : "N/A"} </span>
          </Text>
          <Text fontSize="16px" fontWeight="500" paddingTop="0.2rem">
            <b color="#000">Category:</b> <span>{category || "-"}</span>
          </Text>
        </Flex>
      </Flex>
      <Flex direction="column" justifyContent="space-between">
        <Text
          fontSize="22px"
          fontWeight="600"
          textOverflow="ellipsis"
          className="heading-font"
          paddingTop={4}
          paddingBottom={2}
        >
          {name || "Sample Product Title"}
        </Text>
      </Flex>
      <TableContainer
        border={"1px solid"}
        borderColor={"#b1b1b1"}
        borderRadius={5}
      >
        <Table variant="simple" size="md">
          <Thead backgroundColor="#f44336">
            <Tr>
              <Th color="#fff">Variation</Th>
              <Th color="#fff">Sales Unit</Th>
              <Th color="#fff">{isMobileView ? "Req.Qty" : "Required Qty"}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {sizes?.map((pair, pairIndex) => (
              <Tr key={pairIndex}>
                <Td>{pair.size}</Td>
                <Td>{sales_unit || "N/A"}</Td>
                <Td>
                  <Input
                    type="number"
                    value={inputValues[pairIndex]?.quantity || ""}
                    placeholder="Enter Qty..."
                    onChange={(e) =>
                      handleInputChange(pairIndex, {
                        quantity: parseInt(e.target.value, 10),
                        size: pair.size,
                        _id: pair._id,
                        sales_unit: sales_unit,
                      })
                    }
                  />
                </Td>
                <Td>
                  {isMobileView ? (
                    <Button
                      onClick={() => handleAddToCart(pairIndex)}
                      backgroundColor="#0053a0"
                      w={"2rem"}
                      borderRadius="5px"
                      padding="1rem"
                      fontSize="16px"
                    >
                      <AddIcon />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleAddToCart(pairIndex)}
                      backgroundColor="#0053a0"
                      borderRadius="5px"
                      padding="1.2rem"
                      fontSize="16px"
                    >
                      Add To Cart
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex justifyContent={"flex-end"} paddingY={5}></Flex>
    </Box>
  );
};

export default ProductCard;
