import React, { useEffect, useState } from "react";
import { Header, ProductCard } from "../components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Text,
  useDisclosure,
  Center,
  Flex,
  Button,
  Spinner,
  Heading,
  SimpleGrid,
  Card,
  CardBody,
  Image,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { ApiUrl } from "../../../services/instance/index";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import "./index.css";
import { BannerCard } from "../components/bannerCard";
import { Footer, RenderIf, ScrollContainer } from "../../../components";
import { ArrowRightIcon, PhoneIcon } from "@chakra-ui/icons";
import { icons } from "../../../utils";
import bigfix from "../../../assets/images/BIGFIX.png";
import dafkilit from "../../../assets/images/DAFKILIT.png";
import fuerza from "../../../assets/images/FUREZA.png";
import kruker from "../../../assets/images/KRUKER.png";
import seagull from "../../../assets/images/SEAGULL.png";
import product from "../../../assets/images/p.jpg";
import trust from "../../../assets/images/TRUST.png";
import bossil from "../../../assets/images/bossil.png";
import adhesive from "../../../assets/category/adhesive.png";
import diy from "../../../assets/category/diy.png";
import mro from "../../../assets/category/mro.png";
import mroMrine from "../../../assets/category/mroMrine.png";
import seleants from "../../../assets/category/seleants.png";
import waterproofing from "../../../assets/category/waterproofing.png";
import aboutUs from "../../../assets/images/aboutUs.jpg";



const Home = () => {
  const [brands, setBrands] = useState([]);
  const location = useLocation();
  const [searchData, setSearchData] = useState("");
  const [categories, setCategories] = useState([]);
  const [headerData, setHeaderData] = useState({
    brand: { value: "", label: "" },
    category: { value: "", label: "" },
    search: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [modalData, setModalData] = useState({
    numberOfQty: 1,
    comment: "",
  });
  const [bannerData, setBannerData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [addedProducts, setAddedProducts] = useState({});
  const [offset, setOffset] = useState(0);
  const isMobileView = window.innerWidth <= 962;
const scrollToTop = () => {
  window.scrollTo(0, 0);
};
  useEffect(() => {
    scrollToTop();
    const shopping = JSON.parse(localStorage.getItem("shopping"));
    setAddedProducts(shopping ? shopping.products : {});
  }, []);

  useEffect(() => {
    console.log(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    let sltdSzs = [];
    Object.entries(addedProducts).map(
      ([addedProductKey, addedProductValue], addedProductIndex) => {
        sltdSzs.push({
          size_id: addedProductKey,
          pcs: addedProductValue.pcs,
        });
      }
    );
    setSelectedSizes(sltdSzs);
  }, [addedProducts]);
// const dummyBannerData = [
//   {
//     banner: banner1,
//     target_link: "https://example.com/link1",
//   },
//   {
//     banner: banner2,
//     target_link: "https://example.com/link1",
//   },
//   {
//     banner: banner3,
//     target_link: "https://example.com/link1",
//   },
//   {
//     banner: banner4,
//     target_link: "https://example.com/link1",
//   },
//   {
//     banner: banner5,
//     target_link: "https://example.com/link1",
//   },
// ];

  useEffect(() => {
    console.log(selectedSizes);
  }, [selectedSizes]);

  useEffect(() => {
    setSelectedProduct((prev) => {
      prev = { ...prev };
      prev.qty = modalData.numberOfQty;
      prev.desc = modalData.comment;
      return prev;
    });
  }, [modalData]);

  useEffect(() => {
    let params = {
      category: searchParams.get("category")
        ? categories.find((cat) => cat.value === searchParams.get("category"))
        : { value: "", label: "" },
      brand: searchParams.get("brand")
        ? brands.find((b) => b.value === searchParams.get("brand"))
        : { value: "", label: "" },
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    setHeaderData(params);
    setOffset(0);
  }, [searchParams]);


  useEffect(() => {
    //banners
    axios({
      method: "GET",
      url: `${ApiUrl}v1/get-banners`,
    })
      .then((res) => {
        setBannerData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    //Brands
    setIsLoad(true);
    axios({
      method: "GET",
      url: `${ApiUrl}v1/brands`,
    })
      .then((res) => {
              const brandsData = res.data.data.map((brand) => {
                switch (brand.slug) {
                  case "big-fix":
                    return { ...brand, image: bigfix };
                  case "daf-kilit":
                    return { ...brand, image: dafkilit };
                  case "fuerza":
                    return { ...brand, image: fuerza };
                  case "kruker":
                    return { ...brand, image: kruker };
                  case "seagull":
                    return { ...brand, image: seagull };
                  case "trust":
                    return { ...brand, image: trust };
                  case "bossil":
                    return { ...brand, image: bossil };
                  default:
                    return brand;
                }
              });

      setBrands(brandsData);     
      setIsLoad(false); 
        // setBrands(res.data.data);
      })
      .catch((err) => {
        console.log(err);
              setIsLoad(false); 

      });
    axios({
      method: "GET",
      url: `${ApiUrl}v1/categories`,
    })
         .then((res) => {
              const catData = res.data.data.map((brand) => {
                switch (brand.slug) {
                  case "mro-adhesive-sealant":
                    return { ...brand, image: mro };
                  case "adhesives":
                    return { ...brand, image: adhesive };
                  case "sealants":
                    return { ...brand, image: seleants };
                  case "waterproofing":
                    return { ...brand, image: waterproofing };
                  case "diy":
                    return { ...brand, image: diy };
                  case "automative-mro-marine":
                    return { ...brand, image: mroMrine };
                  default:
                    return brand;
                }
              });
        setCategories(catData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleHeaderData = (event) => {
    let params = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      category: searchParams.get("category")
        ? searchParams.get("category")
        : "",
      // search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params[event.target.name] = ["brand", "category"].includes(
      event.target.name
    )
      ? event.target.value.value
      : event.target.value;
    // setHeaderData(params)
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

 

  const resetHeaderData = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (Input) => (Input.value = "")
    );
    setSearchData("");
    navigate({
      pathname: "",
      search: createSearchParams({
        ...{},
      }).toString(),
    });
  };



  


  return (
    <>
      <RenderIf isTrue={isLoad}>
        <Center
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            position: "fixed",
            zIndex: "1024",
            margin: isMobileView ? "0" : "0px -8rem",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </Center>
      </RenderIf>

      <Header
        categories={categories}
        brands={brands}
        onBrandChange={(event) => handleHeaderData(event)}
        onCategoryChange={(event) => handleHeaderData(event)}
        isMobileView={isMobileView}
        // onSearch={(event) => {
        //   handleSearch(event);
        // }}
        // onCloseSearch={() => {navigate('')}}
        // onCloseSearch={() => navigate('')}
        onSearchValue={(event) => setSearchData(event.target.value)}
        onReset={() => resetHeaderData()}
        // selectedBrand={headerData?.brand}
        // selectedCategory={headerData?.category}
        selectedBrand={headerData?.brand?.value}
        selectedCategory={headerData?.category?.value}
        isCartNotify={Object.keys(addedProducts).length > 0}
        cartNotifyCount={
          Object.keys(addedProducts).length > 0 &&
          Object.keys(addedProducts).length
        }
        searchValue={searchData}
        onCartClick={() => navigate("/cart")}
        onShare={() => {
          console.log(location.pathname);
        }}
      />

      <ScrollContainer marginTop={isMobileView ? "4rem" : "10px"}>
        <BannerCard bannerData={bannerData} />
        <Box padding="8px 0">
          <Box padding="1rem">
            <Heading
              fontSize="3xl"
              mb={4}
              textAlign="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                // border="2px solid  #94bfd2"
                className="border-gradient border-gradient-blue-left"
                width="6rem"
                margin="0 0.5rem"
                height={0}
              ></Box>
              <span> Our Brands </span>
              <Box
                className="border-gradient border-gradient-blue-right"
                width="6rem"
                margin="0 0.5rem"
                height={0}
              ></Box>
            </Heading>
            {isMobileView ? (
              <Box
                display="flex"
                justifyContent="center"
                flexFlow="column"
                alignItems="center"
              >
                {brands.map((brand, index) => (
                  <Card
                    key={index}
                    variant={"outline"}
                    boxShadow="md"
                    onClick={() => navigate(`/products?brand=${brand.slug}`)}
                    width={"18rem"}
                    cursor={"pointer"}
                    margin="3rem 0"
                  >
                    <CardBody padding="0">
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection="column"
                        height={"10rem"}
                      >
                        <Image
                          src={`https://admin.henwoodgroup.ae/admin/images/brands/${brand?.icon}`}
                          alt=""
                          style={{ objectFit: "cover" }}
                          width={"100%"}
                          height={"10rem"}
                          padding="1rem"
                        />{" "}
                        {/* <Text
                          fontSize="sm"
                          textShadow={20}
                          backgroundColor="#0053a0"
                          color="white"
                          fontWeight={600}
                          padding="1rem 0"
                          width="100%"
                          justifyContent="center"
                          textAlign="center"
                          borderRadius="0px 0px 8px 8px"
                        >
                          {brand.name}
                        </Text> */}
                      </Flex>
                    </CardBody>
                  </Card>
                ))}
              </Box>
            ) : (
              <Flex justifyContent="center" alignItems="center">
                {brands.map((brand, index) => (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    // maxW='8rem'
                    margin="0 auto"
                    borderRight={
                      index !== brands.length - 1 ? "3px solid #94bfd2" : "0"
                    }
                  >
                    <Box
                      key={index}
                      // variant={"outline"}
                      boxShadow="0"
                      onClick={() => navigate(`/products?brand=${brand.slug}`)}
                      // width={"16rem"}
                      cursor={"pointer"}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Image
                        src={`https://admin.henwoodgroup.ae/admin/images/brands/${brand?.icon}`}
                        alt=""
                        style={{ objectFit: "contain" }}
                        width={"18rem"}
                        // height={"18rem"}
                        padding="1rem"
                      />{" "}
                      {/* <Text
                          fontSize="sm"
                          textShadow={20}
                          backgroundColor="#0053a0"
                          color="white"
                          fontWeight={600}
                          padding="1rem 0"
                          width="100%"
                          justifyContent="center"
                          textAlign="center"
                          borderRadius="0px 0px 8px 8px"
                        >
                          {brand.name}
                        </Text> */}
                    </Box>
                  </Box>
                ))}
              </Flex>
            )}
          </Box>
          {isMobileView ? (
            <></>
          ) : (
            <Box mt={8} py="2" backgroundColor="#d9e3eb">
              <Flex justifyContent="space-between" flexWrap="wrap">
                <Box padding="2rem 1rem" width={isMobileView ? "100%" : "55%"}>
                  <Text
                    fontSize="2rem"
                    padding="2rem"
                    className="heading-font"
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      // border="2px solid  #94bfd2"
                      className="border-gradient border-gradient-blue-left"
                      width="6rem"
                      margin="0 0.5rem"
                      height={0}
                    ></Box>
                    <span>About Us </span>
                    <Box
                      className="border-gradient border-gradient-blue-right"
                      width="6rem"
                      margin="0 0.5rem"
                      height={0}
                    ></Box>{" "}
                  </Text>
                  <Text fontSize="1.1rem" color="#000" padding="0 2rem">
                    Henwood General Trading LLC has been an indispensable
                    partner to a wide array of industries since 2001.
                    Specializing in supplying high quality hardware, building
                    materials, adhesives, and wood, we have earned a stellar
                    reputation for reliability and excellence. Expanding our
                    offerings to include carpentry items, power tools, and
                    safety equipment, we are committed to meeting the diverse
                    needs of our valued clients.
                  </Text>
                  <Text fontSize="1.1rem" color="#000" padding="0 2rem">
                    Our commitment to prompt delivery and sourcing high-quality
                    products from esteemed manufacturers across the globe,
                    including the U.S.A., Italy, Spain, Taiwan, Malaysia,
                    Indonesia, India, and China, has positioned us as a leader
                    in serving industries such as Furniture, Joineries, Hotels,
                    Marine, and Oil & Gas.
                  </Text>
                  <Text padding="0 2rem">
                    <Button
                      onClick={() => {
                        navigate(`/about-us`);
                      }}
                      width="100px"
                      backgroundColor="#0053a0"
                      marginTop="1rem"
                      color="white"
                    >
                      <span>View More</span> <ArrowRightIcon marginLeft="2px" />
                    </Button>
                  </Text>
                </Box>
                <Box width={isMobileView ? "100%" : "40%"}>
                  {" "}
                  <Image
                    src={aboutUs}
                    alt=""
                    style={{ objectFit: "cover" }}
                    width={"100%"}
                    // height={"18rem"}
                    padding="1rem"
                  />
                </Box>
              </Flex>
            </Box>
          )}
          <Box mt={8} padding="1rem">
            <Heading
              fontSize="3xl"
              mb={4}
              textAlign="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                // border="2px solid  #94bfd2"
                className="border-gradient border-gradient-blue-left"
                width="6rem"
                margin="0 0.5rem"
                height={0}
              ></Box>
              <span> OUR PRODUCTS CATEGORIES</span>
              <Box
                className="border-gradient border-gradient-blue-right"
                width="6rem"
                margin="0 0.5rem"
                height={0}
              ></Box>
            </Heading>

            <Flex flexFlow="wrap" padding="0 2rem">
              {categories.map((category, index) => (
                <Card
                  key={index}
                  variant={"outline"}
                  boxShadow="md"
                  width={isMobileView ? "18rem" : "20rem"}
                  border={isMobileView ? "3px solid #0053a0" : "0"}
                  padding={isMobileView ? "1rem" : "0"}
                  margin="1rem"
                >
                  <CardBody padding="0">
                    {isMobileView ? (
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection="column"
                        maxH={"22rem"}
                      >
                        <Text
                          fontSize="18px"
                          textShadow={20}
                          // backgroundColor="#0053a0"
                          // color="white"
                          fontWeight={600}
                          padding="1rem"
                          width="100%"
                          // justifyContent="center"
                          textAlign="center"
                          // borderRadius="0px 0px 8px 8px"
                        >
                          {category.name}
                        </Text>
                        <Button
                          colorScheme="blue"
                          background="#0053a0"
                          width="50%"
                          borderRadius="4px"
                          onClick={() =>
                            navigate(`/products?category=${category.slug}`)
                          }
                        >
                          View More
                        </Button>
                      </Flex>
                    ) : (
                      <Flex
                        justifyContent="space-between"
                        // alignItems="center"
                        flexDirection="column"
                        height={"22rem"}
                        position="relative"
                      >
                        <Image
                          src={`https://admin.henwoodgroup.ae/admin/images/categories/${category?.icon}`}
                          alt=""
                          borderRadius="15px"
                          style={{ objectFit: "cover" }}
                          width={"100%"}
                          height={"100%"}
                          // padding="1rem"
                        />{" "}
                        <Box
                          position="absolute"
                          width="100%"
                          height="100%"
                          borderRadius="15px"
                          style={{
                            backgroundImage:
                              "linear-gradient(rgba(255, 122, 89,0), #000)",
                          }}
                          display="flex"
                          flexDir="column"
                          justifyContent="end"
                        >
                          <Box
                            display="flex"
                            flexDir="row"
                            justifyContent="space-between"
                            marginBottom="1rem"
                            padding="0 0.5rem"
                          >
                            <Text
                              fontSize="18px"
                              textShadow={20}
                              // backgroundColor="#0053a0"
                              color="white"
                              fontWeight={600}
                              // padding="1rem 0"
                              padding="0 1rem"
                              width="100%"
                              // justifyContent="center"
                              // textAlign="center"
                              // borderRadius="0px 0px 8px 8px"
                            >
                              {category.name}
                            </Text>
                            <Button
                              colorScheme="blue"
                              background="#0053a0"
                              width="50%"
                              borderRadius="4px"
                              onClick={() =>
                                navigate(`/products?category=${category.slug}`)
                              }
                            >
                              <span> View More </span>{" "}
                              <ArrowRightIcon marginLeft="2px" />
                            </Button>
                          </Box>
                        </Box>
                      </Flex>
                    )}
                  </CardBody>
                </Card>
              ))}
            </Flex>
            {/* </SimpleGrid> */}
          </Box>
          {/* <Box mt={8} backgroundColor="#d9e3eb" minH="15rem" padding="1rem">
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Card
                padding="1.5rem"
                background="linear-gradient(180deg, #FEFFFF 38%, #41a5ee 100%);"
                justifyContent="center"
                alignItems="center"
                width={isMobileView ? "100%" : "45%"}
                margin="1rem"
              >
                <Image src={icons.user} width="80px" />
                <Heading fontSize="22px" padding="0.5rem 0" color="#0053a0">
                  ABOUT US
                </Heading>
                <Text textAlign="center" padding="0.5rem 0" color="gray">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Text>
                <Button
                  colorScheme="blue"
                  background="#0053a0"
                  // color="white"
                  variant="solid"
                  margin="1rem 0"
                  onClick={() => {
                    navigate(`/about-us`);
                  }}
                >
                  About Us
                </Button>
              </Card>
              <Card
                padding="1.5rem"
                // backgroundColor="#fff"
                background="linear-gradient(180deg, #FEFFFF 38%, #41a5ee 100%);"
                justifyContent="center"
                alignItems="center"
                width={isMobileView ? "100%" : "45%"}
                margin="1rem"
              >
                <PhoneIcon width="80px" height="80px" color="#41a5ee" />
                <Heading fontSize="22px" padding="0.5rem 0" color="#0053a0">
                  CONTACT US
                </Heading>
                <Text textAlign="center" padding="0.5rem 0" color="gray">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Text>
                <Button
                  colorScheme="blue"
                  background="#0053a0"
                  variant="solid"
                  margin="1rem 0"
                  onClick={() => {
                    navigate(`/contact`);
                  }}
                >
                  Contact Us
                </Button>
              </Card>
            </Flex>
          </Box> */}
          <Footer />
        </Box>
      </ScrollContainer>
    </>
  );
};

export default Home;
