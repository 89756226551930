import React, { useEffect, useState } from "react";
import { Header, ProductCard } from "../components";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Box,
  Text,
  useDisclosure,
  Center,
  Flex,
  Button,
  Spinner,
  Heading,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Card,
  CardBody,
  Image,
} from "@chakra-ui/react";
import { ApiUrl } from "../../../services/instance/index";
import axios from "axios";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from "react-router-dom";
import "./index.css";
import { BannerCard } from "../components/bannerCard";
import { Footer, RenderIf, ScrollContainer } from "../../../components";
import { ArrowRightIcon } from "@chakra-ui/icons";
import product from '../../../assets/images/p.jpg'
import product2 from "../../../assets/images/p2.jpg";

const AboutUs = () => {
  const [brands, setBrands] = useState([]);
  const location = useLocation();
  const [searchData, setSearchData] = useState("");
  const [categories, setCategories] = useState([]);
  const [headerData, setHeaderData] = useState({
    brand: { value: "", label: "" },
    category: { value: "", label: "" },
    search: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [modalData, setModalData] = useState({
    numberOfQty: 1,
    comment: "",
  });
  const [aboutUsData, setAboutUsData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [addedProducts, setAddedProducts] = useState({});
  const [offset, setOffset] = useState(0);
  const isMobileView = window.innerWidth <= 962;

  useEffect(() => {
    const shopping = JSON.parse(localStorage.getItem("shopping"));
    setAddedProducts(shopping ? shopping.products : {});
  }, []);
useEffect(() => {
  //banners
  axios({
    method: "GET",
    url: `${ApiUrl}v1/about-us`,
  })
    .then((res) => {
      console.log("banners...", res);
      setAboutUsData(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
}, []);
  useEffect(() => {
    console.log(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    let sltdSzs = [];
    Object.entries(addedProducts).map(
      ([addedProductKey, addedProductValue], addedProductIndex) => {
        sltdSzs.push({
          size_id: addedProductKey,
          pcs: addedProductValue.pcs,
        });
      }
    );
    setSelectedSizes(sltdSzs);
  }, [addedProducts]);
  const dummyBannerData = [
    {
      banner:
        "https://as2.ftcdn.net/v2/jpg/03/00/98/07/1000_F_300980743_5YrcmC0fWo7Ts2I2Sh2YjvybCcPAp0ak.jpg",
      target_link: "https://example.com/link1",
    },
    {
      banner:
        "https://www.eisc.com/wp-content/uploads/2019/05/cutting-tools-banner.jpg",
      target_link: "https://example.com/link2",
    },
    {
      banner:
        "https://5.imimg.com/data5/SELLER/Default/2020/8/SZ/MN/NY/15274315/bolt.png",
      target_link: "https://example.com/link2",
    },
    // Add more dummy data as needed
  ];

  useEffect(() => {
    console.log(selectedSizes);
  }, [selectedSizes]);

  useEffect(() => {
    setSelectedProduct((prev) => {
      prev = { ...prev };
      prev.qty = modalData.numberOfQty;
      prev.desc = modalData.comment;
      return prev;
    });
  }, [modalData]);

  useEffect(() => {
    let params = {
      category: searchParams.get("category")
        ? categories.find((cat) => cat.value === searchParams.get("category"))
        : { value: "", label: "" },
      brand: searchParams.get("brand")
        ? brands.find((b) => b.value === searchParams.get("brand"))
        : { value: "", label: "" },
      search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    setHeaderData(params);
    setOffset(0);
  }, [searchParams]);

 

  useEffect(() => {
    //Brands
    axios({
      method: "GET",
      url: `${ApiUrl}v1/brands`,
    })
      .then((res) => {
        setBrands(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios({
      method: "GET",
      url: `${ApiUrl}v1/categories`,
    })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleHeaderData = (event) => {
    let params = {
      brand: searchParams.get("brand") ? searchParams.get("brand") : "",
      category: searchParams.get("category")
        ? searchParams.get("category")
        : "",
      // search: searchParams.get("search") ? searchParams.get("search") : "",
    };
    params[event.target.name] = ["brand", "category"].includes(
      event.target.name
    )
      ? event.target.value.value
      : event.target.value;
    // setHeaderData(params)
    navigate({
      pathname: "",
      search: createSearchParams({
        ...params,
      }).toString(),
    });
  };

  const resetHeaderData = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (Input) => (Input.value = "")
    );
    setSearchData("");
    navigate({
      pathname: "",
      search: createSearchParams({
        ...{},
      }).toString(),
    });
  };

  return (
    <>
      <RenderIf isTrue={isLoad}>
        <Center
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            position: "fixed",
            zIndex: "1024",
            margin: isMobileView ? "0" : "0px -8rem",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </Center>
      </RenderIf>

      <Header
        categories={categories}
        brands={brands}
        onBrandChange={(event) => handleHeaderData(event)}
        onCategoryChange={(event) => handleHeaderData(event)}
        isMobileView={isMobileView}
        // onSearch={(event) => {
        //   handleSearch(event);
        // }}
        // onCloseSearch={() => {navigate('')}}
        // onCloseSearch={() => navigate('')}
        onSearchValue={(event) => setSearchData(event.target.value)}
        onReset={() => resetHeaderData()}
        // selectedBrand={headerData?.brand}
        // selectedCategory={headerData?.category}
        selectedBrand={headerData?.brand?.value}
        selectedCategory={headerData?.category?.value}
        isCartNotify={Object.keys(addedProducts).length > 0}
        cartNotifyCount={
          Object.keys(addedProducts).length > 0 &&
          Object.keys(addedProducts).length
        }
        searchValue={searchData}
        onCartClick={() => navigate("/cart")}
        onShare={() => {
          console.log(location.pathname);
        }}
      />

      <ScrollContainer marginTop={isMobileView ? "4rem" : "10px"}>
        <BannerCard bannerData={dummyBannerData} />
        <Box padding="8px 0">
          <Box
            mt={8}
            //  backgroundColor="#d9e3eb"
          >
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Box
                padding="1rem"
                width={isMobileView ? "100%" : "55%"}
                className="myComponent"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: aboutUsData.section1 }}
                />
              
              </Box>
              <Box width={isMobileView ? "100%" : "35%"}>
                {" "}
                <Image
                  // src={product}
                  src={`https://admin.henwoodgroup.ae/admin/images/about-us/${aboutUsData?.icon1}`}
                  alt=""
                  style={{ objectFit: "cover" }}
                  width={"100%"}
                  // height={"18rem"}
                  padding="2rem"
                />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" flexWrap="wrap">
              <Box width={isMobileView ? "100%" : "35%"}>
                {" "}
                <Image
                  // src={product2}
                  src={`https://admin.henwoodgroup.ae/admin/images/about-us/${aboutUsData?.icon2}`}
                  alt=""
                  style={{ objectFit: "cover" }}
                  width={"100%"}
                  // height={"18rem"}
                  padding="2rem"
                />
              </Box>
              <Box
                padding="1rem"
                width={isMobileView ? "100%" : "55%"}
                className="myComponent"
              >
                <div
                  dangerouslySetInnerHTML={{ __html: aboutUsData.section2 }}
                />
                
              </Box>
            </Flex>
          </Box>
        </Box>
      </ScrollContainer>
      <Footer />
    </>
  );
};

export default AboutUs;
