import React, { useEffect, useState } from "react";
import {
  ProductInfo,
  UserInformation,
  OrderSummary,
  Header,
} from "../components";
import { json, useNavigate } from "react-router-dom";
import { Box, Flex, Button as B, Center, Spacer, Text } from "@chakra-ui/react";
import axios from "axios";
import { ApiUrl } from "../../../services/instance/index";
import "./index.css";
import { Button, RenderIf, ScrollContainer } from "../../../components";
import toast from "react-hot-toast";

const Cart = () => {
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [products, setProducts] = useState([]);
  const [summaryList, setSummaryList] = useState([]);
  const [comments, setComments] = useState({});
  const [isTransportInput, setIsTransportInput] = useState(false);
  const isMobileView = window.innerWidth <= 962;

  const [userInfo, setUserInfo] = useState({
    // name: "",
    phone: "",
    city: "",
    comment: "",
  });

  useEffect(() => {
    let sls = [];
    Object.entries(products).forEach(([prdKey, prdValue]) => {
      let price = 0;
      let name = "";
      let pcs = 0;
      let brand_name = "";
      let offerPrice = 0;
      let sales_unit = "N/A";
      console.log(prdValue);
      prdValue.forEach((s) => {
        pcs = parseInt(pcs,10) + parseInt(s.pcs,10);
        name = s.name;
        brand_name = s.brand;
        offerPrice = s.offerPrice;
        price = price + s.offerPrice * parseInt(s.pcs,10);
        sales_unit = s.sales_unit;

      });
      sls.push({
        name,
        pcs,
        brand_name,
        price,
        offerPrice,
        sales_unit,
      });
    });
    setSummaryList(sls);
  }, [products]);

  useEffect(() => {
    localStorage.setItem("summary", JSON.stringify(summaryList));
  }, [summaryList]);

  useEffect(() => {
    let products = JSON.parse(localStorage.getItem("shopping"))?.products ?? {};
    let newProducts = {};
    Object.entries(products).forEach(([productKey, productValue]) => {
      if (newProducts[`${productValue.code} ${productValue.color}`]) {
        newProducts[`${productValue.code} ${productValue.color}`].push(
          productValue
        );
      } else {
        newProducts[`${productValue.code} ${productValue.color}`] = [
          productValue,
        ];
      }
    });
    // console.log(newProducts);
    setProducts(newProducts);
  }, []);

  const handleQuantity = (data) => {
    console.log(data);
    let duplicateProducts = { ...products };
    duplicateProducts[data.code].forEach((dp, dpIndex) => {
      if (dp.size_id === data.size_id) {
        dp.pcs = data.pcs;
      }
    });
    setProducts(duplicateProducts);
    // let shopping = JSON.parse(localStorage.getItem("shopping"));
    console.log(data);
    let local_store = JSON.parse(localStorage.getItem('shopping'))?.products
    console.log(local_store)
    local_store[data.size_id].pcs = data.pcs
    localStorage.setItem('shopping',JSON.stringify({'products':local_store}))
    // shopping.products = duplicateProducts
    // localStorage.setItem('shopping',JSON.stringify(shopping))
  };

  const handleRemoveSize = (data) => {
    let duplicateProducts = { ...products };
    let ind = 0;
    if (duplicateProducts[data.ucode]) {
      duplicateProducts[data.ucode].forEach((dp, dpIndex) => {
        if (dp.size_id === data.size_id) {
          ind = dpIndex;
        }
      });
    }
    if (duplicateProducts[data.ucode].length === 1) {
      delete duplicateProducts[data.ucode];
    } else {
      duplicateProducts[data.ucode].splice(ind, 1);
    }
    setProducts(duplicateProducts);

    let lsProducts = JSON.parse(localStorage.getItem("shopping")).products;
    delete lsProducts[data.size_id];
    localStorage.setItem("shopping", JSON.stringify({ products: lsProducts }));
  };

  const handleUserData = (data) => {
    if (data.target.name === "transport_from_indore" && data.target.value === "others") {
      setIsTransportInput(true)
      return;

    }
    else if
      (data.target.name === "transport_from_indore" && data.target.value !== "others") {
      setIsTransportInput(false)
    }

    setUserInfo((prev) => {
      prev = { ...prev };

      if (data.target.name === "phone") {
        if (
          data.target.value.length <= 10 &&
          /^[0-9]*$/.test(data.target.value)
        ) {
          prev[data.target.name] = data.target.value;
        }
      } else {
        prev[data.target.name === "transport_from_indore2" ? "transport_from_indore" : data.target.name] = data.target.value;
      }
      return prev;
    });
  };

  useEffect(() => {
    // console.log(userInfo);
  }, [userInfo]);

  const getAllItems = () => {
    let arrangedData = [];
    let subtotal = 0;
    let total = 0;
    let discount = 0;
    // console.log(products);
    Object.entries(products).forEach(([arrangDKey, arrangDValue]) => {
      arrangDValue.forEach((aD) => {
        arrangedData.push({
          size: aD.size,
          MOQ: aD.moq,
          price: aD.offerPrice,
          qty: aD.pcs,
          color: aD.color,
          code: aD.code,
          comment: comments[arrangDKey]?.filter((item) => item?.size == aD?.size)[0]?.comment
           ,
          total: aD.offerPrice * aD.pcs,
        });
        subtotal = subtotal + aD.salePrice * aD.pcs;
        total = total + aD.offerPrice * aD.pcs;
        discount = discount + aD.discountPrice * aD.pcs;
      });
    });
    return [arrangedData, subtotal, total, discount];
  };

  const handleCheckOut = (type = "c") => {
    //console.log(userInfo?.transport_from_indore)
    setIsLoad((prev) => !prev);
    axios({
      method: "post",
      url: `${ApiUrl}v1/place-order`,

      data: {
        shop_name: userInfo.shop_name,
        // transport_from_indore: userInfo.transport_from_indore,
        transport_remark: userInfo.transport_remark,
        // name: userInfo.name,
        isd: "971",
        mobile: userInfo.phone,
        city: userInfo.city,
        items: getAllItems()[0],
        subtotal: getAllItems()[1],
        total: getAllItems()[2],
        discount: getAllItems()[3],
      },
    })
      .then((res) => {
        setIsLoad((prev) => !prev);
        console.log("placed order", res);
        localStorage.setItem("order_number", res.data.product["order_number"]);
        localStorage.setItem("receipt_id", res.data.product["_id"]);

        if (type === "c&d") {
          navigate("/order-placed?auto_dowload=true");
        } else {
          navigate("/order-placed");
        }
      })
      .catch((err) => {
        setIsLoad((prev) => !prev);
        toast.error("Error! Something Went Wrong!", {
          position: "top-right",
        });
        // console.log(err);
      });
  };

  const handleComments = (data) => {
    console.log("datacomment", data);
    let duplicateComments = { ...comments };
    duplicateComments[`${data.code} ${data.color}`] = data.comments;
    setComments(duplicateComments);
  };

  const checkDisabled = () => {
    let duplicateUserInfo = { ...userInfo };
    delete duplicateUserInfo["comment"];
    delete duplicateUserInfo["name"];
    return Object.values(duplicateUserInfo).includes("");
  };

  useEffect(() => {
    // console.log(comments);
  }, [comments]);

  return (
    <>
      <RenderIf isTrue={isLoad}>
        <Center
          style={{
            height: "100vh",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            position: "fixed",
            zIndex: "1024",
            margin: isMobileView ? "0" : "0px -8rem",
          }}
        >
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </Center>
      </RenderIf>
      <Header onArrowClick={() => navigate("/home")} />
      <Box marginTop="5rem">
        <RenderIf isTrue={Object.keys(products).length === 0}>
          <Flex
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100vh"}
          >
            Oops! You cart is empty.....
            <B onClick={() => navigate("/home")}>Go to home</B>
          </Flex>
        </RenderIf>
        <RenderIf isTrue={Object.keys(products).length > 0}>
          <ScrollContainer>
            {Object.entries(products).map(
              ([productKey, productValue], productIndex) => {
                return (
                  <ProductInfo
                    size={productValue[0]?.size}
                    productKey={productValue[0]?.code}
                    color={productValue[0]?.color}
                    setComments={(data) => handleComments(data)}
                    price={productValue[0]?.offerPrice}
                    salePrice={productValue[0]?.salePrice}
                    offerPrice={productValue[0]?.offerPrice}
                    discount={productValue[0]?.discount}
                    discountPrice={productValue[0]?.discountPrice}
                    name={productValue[0]?.name}
                    image={productValue[0]?.image}
                    tableData={productValue}
                    key={productIndex}
                    onChangeQuantity={(data) => handleQuantity(data)}
                    onRemoveSize={(data) => handleRemoveSize(data)}
                    isFocus={productIndex === 0 ? true : false}
                    sales_unit={productValue[0]?.sales_unit}
                  />
                );
              }
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCheckOut("c&d");
              }}
            >
              <UserInformation
                getUserInfo={(data) => {
                  handleUserData(data);
                }}
                userInfo={userInfo}
                // isTransportInput={isTransportInput}
              />
              <OrderSummary list={summaryList} />

              <Box pb={5} width="100%">
                {/* <Button
              w="100%"
              onClick={() => {
                handleCheckOut();
              }}
              disabled={Object.keys(userInfo).length === 0 || checkDisabled()}
              _hover={{
                opacity: 1,
                background: "#0053a0",
              }}
              _active={{
                background: "#0053a0",
              }}
            >
              Checkout
            </Button>  */}

                <Button
                  w="94%"
                  marginTop={"0px"}
                  type="submit"
                  height="40px"
                  onClick={(e) => {
                    e.preventDefault();

                    if (
                      !userInfo.shop_name ||
                      !userInfo.phone ||
                      !userInfo.city ||
                      !userInfo.city
                    ) {
                      alert("Please fill required fields");
                    } else {
                      handleCheckOut("c&d");
                      console.log("getUserInfo");
                    }
                    // console.log(userInfo)
                  }}
                  disabled={Object.keys(userInfo).length === 0}
                  _hover={{
                    opacity: 1,
                    background: "#0053a0",
                  }}
                  _active={{
                    background: "#0053a0",
                  }}
                >
                  Get Quote
                </Button>
              </Box>
            </form>
            <Box
              style={{
                marginLeft: "17px",
                p: "10px",
                marginTop: "-10px",
                marginBottom: "10px",
              }}
            >
              <div>
                {" "}
                <Text fontSize={"10px"} fontWeight={"bold"}>
                  Terms & Conditions :-{" "}
                </Text>
                <Text fontSize={"8px"}>
                  1. Order will be dispatched upon stock availability.
                </Text>
                {/* <Text fontSize={"8px"}>
                  3. Order will be confirmed upon bank confirmation.
                </Text> */}
              </div>
            </Box>
          </ScrollContainer>
        </RenderIf>
      </Box>
    </>
  );
};
export default Cart;
