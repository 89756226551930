import React from "react";
import { Box, Image, Flex, Text, Grid, GridItem, Button } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
const BannerCard = ({
  bannerData
}) => {

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
  autoplaySpeed: 3000,
   
  };
    const navigate = useNavigate();
  const isMobileView = window.innerWidth <= 962;

  return (
    <Box
      width="100%"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      paddingBottom={6}
      marginBottom={4}
      position="relative"
    >
      <Slider {...settings}>
        {bannerData.map((item) => (
          <Flex
            width="100%"
            direction={"column"}
            justifyContent="center"
            alignItems="center"
            backgroundColor="#F5F5F5"
            minH="28rem"
            maxH="30rem"
            borderTop={"1px solid #0053a0"}
            onClick={() => {
              if (item?.target_link) {
                window.open(item?.target_link, "_blank");
              }
            }}
          >
            <Image
              src={item?.banner}
              alt=""
              style={{ objectFit: "cover" }}
              width={"100%"}
              height={"28rem"}
            />
          </Flex>
        ))}
      </Slider>
      <Flex
        position="absolute"
        top="0"
        height="94%"
        width="100%"
        background="linear-gradient(to right, #003ca3, rgba(255, 122, 89,0))"
      >
        <Box
          width="70%"
          padding="1rem 2.5rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            color="white"
            fontSize={isMobileView ? "1rem" : "2rem"}
            fontWeight="600"
          >
            Henwood General Trading LLC
          </Text>
          <Text fontSize="1rem" fontWeight="500" color="white">
            Henwood General Trading LLC has been an indispensable partner to a
            wide array of industries since 2001. Specializing in supplying high
            quality hardware, building materials, adhesives, and wood, we have
            earned a stellar reputation for reliability and excellence. eiusmod
            tempor incididunt ut labore et dolore magna aliqua.
          </Text>
          <Button
            onClick={() => {
              navigate(`/about-us`);
            }}
            width="100px"
            backgroundColor="#0053a0"
            marginTop="1rem"
            color="white"
          >
            About US
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default BannerCard;
