import React, { useState, useRef } from "react";
import {
  Box,
  Center,
  Container,
  Flex,
  Image,
  Spacer,
  Text,
} from "@chakra-ui/react";
import "./header.css";
import logo from "../../../../assets/images/logo.png";
import { RWebShare } from "react-web-share";
import { useEffect } from "react";
import { Button, Input, SelectBox } from "../../../../components";
import { icons } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";

const Header = ({
  isCartNotify,
  cartNotifyCount,
  onCartClick,
  isMobileView,
}) => {
  const [urlPath, setUrlPath] = useState("home");
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname === "/") setUrlPath("home");
    else if (window.location.pathname === "/about-us") setUrlPath("about-us");
    else if (window.location.pathname === "/contact") setUrlPath("contact");
    else if (window.location.pathname === "/products") setUrlPath("products");
    else setUrlPath("");
  }, [window.location.pathname]);

  return (
    <Container
      // h={"15rem"}
      position={isMobileView ? "fixed" : "sticky"}
      maxW={"100%"}
      top={0}
      zIndex={1024}
      padding="0"
      backgroundColor={"#ffffff"}
      // padding={isMobileView ? "0" : "0 5rem"}
    >
      <Flex
        w="100%"
        justifyContent={"space-between"}
        flexDirection="column"
        pt={2.5}
      >
        <Flex
          w="100%"
          justifyContent={"space-between"}
          flexDirection="row"
          pt={2.5}
        >
          {!isMobileView && (
            <Center>
              <Flex
                justifyContent={"space-between"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  {" "}
                  <Image src={logo} width={isMobileView ? "3rem" : "6rem"} />
                </Box>
                {/* <Text>HENWOOD</Text> */}
              </Flex>
            </Center>
          )}
          {!isMobileView && (
            <Center>
              <Box
                padding="0 1rem"
                borderRadius="15px"
                backgroundColor="#0071a4"
                color="#fff"
              >
                <Flex
                  justifyContent={"space-between"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Text display="flex" alignItems="center">
                    {" "}
                    <EmailIcon
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                    <Text padding="1rem 0">info@henwood.com</Text>
                  </Text>
                  <Text display="flex" alignItems="center">
                    {" "}
                    <PhoneIcon
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                    <Text padding="1rem 0">+97142286595</Text>
                  </Text>
                  <Text display="flex" alignItems="center">
                    <Image
                      src={icons.facebookh}
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                  </Text>{" "}
                  <Text display="flex" alignItems="center">
                    <Image
                      src={icons.twitterh}
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                  </Text>{" "}
                  <Text display="flex" alignItems="center">
                    <Image
                      src={icons.linkedinh}
                      width="20px"
                      height="20px"
                      color="#fff"
                      margin="0 0.5rem"
                    />
                  </Text>{" "}
                </Flex>{" "}
              </Box>
            </Center>
          )}
        </Flex>
        <Flex
          justifyContent={"space-between"}
          alignContent={"center"}
          alignItems={"center"}
          backgroundColor={isMobileView ? "#d6eef8" : "#94bfd2"}
          marginTop={isMobileView ? "0" : "1rem"}
          lineHeight="45px"
          padding={isMobileView ? "0" : "0.5rem"}
        >
          {isMobileView ? (
            <>
              <span>
                <Image src={logo} width={isMobileView ? "3rem" : "6rem"} />
              </span>
              {showMenu && (
                <Flex
                  justifyContent={"space-evenly"}
                  alignContent={"center"}
                  alignItems={"center"}
                  flexFlow={"column"}
                  minH={"5rem"}
                  position={"absolute"}
                  right={"2.5rem"}
                  top={"4rem"}
                  backgroundColor={"#d4d4d4"}
                  padding={"1rem"}
                  zIndex="1"
                >
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "home"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/`);
                    }}
                  >
                    Home
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "about-us"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/about-us`);
                    }}
                  >
                    About US
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "products"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/products`);
                    }}
                  >
                    Our Products
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="1rem"
                    className={
                      urlPath == "contact"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/contact`);
                    }}
                  >
                    Contact
                  </Text>
                </Flex>
              )}
            </>
          ) : (
            <>
              <Center></Center>
              <Center>
                <Flex
                  justifyContent={"space-between"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "home"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/`);
                    }}
                  >
                    Home
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "about-us"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/about-us`);
                    }}
                  >
                    About US
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "products"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/products`);
                    }}
                  >
                    Our Products
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    padding="0 1rem"
                    className={
                      urlPath == "contact"
                        ? "active heading-font"
                        : "head-link heading-font"
                    }
                    onClick={() => {
                      navigate(`/contact`);
                    }}
                  >
                    Contact
                  </Text>
                </Flex>
              </Center>
            </>
          )}
          <Center padding="0 0.5rem">
            <span onClick={() => onCartClick()}>
              <Button
                background="white"
                w="26px"
                isNotify={isCartNotify}
                notifyCount={cartNotifyCount}
                border={"none"}
              >
                <Image ml="0px" className="heading" src={icons.cart} />
              </Button>
            </span>
            <span
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              {isMobileView && (
                <Button background="white" w="26px" border={"none"}>
                  <Image ml="0px" width={5} height={5} src={icons.more} />
                </Button>
              )}
            </span>
          </Center>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Header;
