import React from "react";
import "./themeProvider.scss"
import { Box } from "@chakra-ui/react";

const ThemeProvider = ({children}) => {
      const isMobileView = window.innerWidth <= 962;

    return (
      <>
        <Box padding={isMobileView?"0":'0 8rem'}>{children}</Box>
      </>
    );
}

export default ThemeProvider;