import React from "react";
import { Routes as R, Route } from "react-router-dom";
import routes from "./config";
import { ResponsiveView } from "../layouts/responsiveView";

const Routes = () => {
    return (  
        <ResponsiveView>
             <R>
                {
                    routes.map((route, routeIndex) => (
                        <Route {...route} key={routeIndex}></Route>
                    ))
                }
            </R>
        </ResponsiveView>
    )
}

export default Routes;