import { Box, Heading, Text, Select, Spacer, Grid } from "@chakra-ui/react";
import {
  FormControl,
  FormLabel,

} from "@chakra-ui/react";

import React, { useState } from "react";
import { Input } from "../../../../components";

const UserInformation = ({ getUserInfo, userInfo, isTransportInput }) => {
  // const [istransportDisable, setTransportDisable] = useState(true);
  // const [input, setInput] = useState("");

  // const [isDrop, setIsDrop] = useState(false);


  return (
    <Box p={4} m={15} borderRadius="3%" border="2px solid #DADADA">
      <Box>
        <Heading as="h5" size="sm" fontFamily={"Roboto"}>
          Billing Information
        </Heading>
      </Box>

      <FormControl isRequired mt="8px">
      <Grid  >
        <FormLabel fontSize={14}>Shop Name</FormLabel>
        <Text fontSize={8}  marginTop={'-24px'} marginLeft={'97px'} >(Required)</Text> 
         </Grid>
        <Input
          required
          fontFamily={"Roboto"}
          h="30px"
          name="shop_name"
          onChange={(event) => {
            getUserInfo(event);
          }}
        />
      </FormControl>

      {/* <Box mt="8px">
        <Text fontSize={14} fontFamily={"Roboto"}>
        Personal Name </Text>
        <Input
          fontFamily={"Roboto"}
          h="30px"
          name="name"
          onChange={(event) => {
            getUserInfo(event);
          }}
        />
      </Box> */}

      <FormControl isRequired mt="8px">
      <Grid  >
        <FormLabel fontSize={14}>Mobile no.</FormLabel>
        <Text fontSize={8} marginTop={'-24px'} marginLeft={'89px'}   >(Required)</Text> 
         </Grid>
        <Input
          required
          fontFamily={"Roboto"}
          h="30px"
          name="phone"
          value={userInfo?.phone}
          onChange={(event) => {
            getUserInfo(event);
          }}
        />
      </FormControl>

      <FormControl isRequired mt="8px">
        <Grid templateColumns='repeat(5, 1fr)' >
        <FormLabel fontSize={14} >City</FormLabel>
       <Text fontSize={8} colSpan={1} marginTop={'5px'} marginLeft={'-20px'}>(Required)</Text>  </Grid>
        <Input
          required
          fontFamily={"Roboto"}
          h="30px"
          name="city"
          onChange={(event) => {
            getUserInfo(event);
          }}
        />
      </FormControl>
    

      {/* <Box mt="8px">
        <Text fontSize={14} fontFamily={"Roboto"}>
          Transport
        </Text>
        <Select
       
          name="transport_from_indore"
          placeholder="Select Transport"
          onChange={(event) => {
            getUserInfo({
              target: {
                name: "transport_from_indore",
                value: event.target.value,
              },
            });
            console.log(event.target.value);
          }}
        >
          <option value="Ahmedabad">Ahmedabad</option>
          <option value="Bhiwandi">Bhiwandi</option>
          <option value="Delhi">Delhi</option>
          <option value="Indore">Indore</option>
          <option value="kanpur">Kanpur</option>
          <option value="Jaipur">Jaipur</option>
          <option value="Udaipur">Udaipur</option>
          <option value="others">others</option>
        </Select>
      </Box> */}

      {/* {isTransportInput && (
        <Box mt="8px">
          <Text fontSize={14}>Other Transport</Text>
          <Input

            fontFamily={"Roboto"}
            h="30px"
            name="transport_from_indore2"
            onChange={(event) => {
              getUserInfo(event);
            }}
          />
        </Box>
      )} */}

      <Box mt="8px">
        <Text fontSize={14} fontFamily={"Roboto"} >
          Remark</Text>
        <Input

          fontFamily={"Roboto"}
          h="30px"
          name="transport_remark"
          onChange={(event) => {
            getUserInfo(event);
          }}
        />
      </Box>
    </Box>
  );
};

export default UserInformation;
