import {
  Box,
  Table as T,
  Flex,
  Spacer,
  Heading,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import React from "react";

const OrderSummary = ({ list }) => {
  const getTotal = () => {
    let t = {
      pcs: 0,
      offerPrice: 0,
      price: 0,
    };
    list.forEach((ls) => {
          console.log(t);

      t.qty = (t.qty ? t.qty : 0) + ls.qty;
      t.pcs = parseInt(t.pcs, 10) + parseInt(ls.pcs, 10);

      t.price = t.price + ls.price;
    });
    console.log(t);
    return t;
  };
  return (
    <>
      <Box p={5} m={15} borderRadius="3%" bg="#DADADA">
        <Heading
          as="h5"
          size="md"
          pb={1}
          fontFamily={"Roboto"}
          borderBottom={"1px solid rgba(0,0,0,0.5)"}
        >
          Estimate
        </Heading>
        <TableContainer>
          <T size="sm">
            <Thead>
              <Tr
                fontStyle="Roboto"
                fontWeight="400"
                fontSize="12px"
                color="#000000"
                bgColor="#F5F5F5"
              >
                <Th textAlign="center">Brand </Th>
                <Th textAlign="center"> Name</Th>
                <Th textAlign="center">Sales Unit</Th>
                <Th textAlign="center"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {list.map((ls, lsIndex) => (
                <Tr mt="10px" key={lsIndex}>
                  <Td
                    textAlign="center"
                    color="#767676"
                    fontSize="10px"
                    w={"30%"}
                    fontFamily={"Roboto"}
                  >
                    {ls?.brand_name.split("_").join(" ")}
                  </Td>
                  <Td
                    textAlign="center"
                    color="#767676"
                    fontSize="10px"
                    w={"30%"}
                    fontFamily={"Roboto"}
                  >
                    {ls.name}
                  </Td>
                  <Td
                    textAlign="center"
                    color="#767676"
                    fontSize="10px"
                    w={"30%"}
                    fontFamily={"Roboto"}
                  >
                    {ls.sales_unit}
                  </Td>
                  <Spacer />
                  <Td
                    textAlign="center"
                    color="#767676"
                    fontSize="10px"
                    w={"20%"}
                    fontFamily={"Roboto"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    {ls.pcs}
                    {/* ({ls.offerPrice}) */}
                  </Td>
                  <Spacer />
                </Tr>
              ))}
            </Tbody>
          </T>
        </TableContainer>
      </Box>
    </>
  );
};

export default OrderSummary;
