import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import bossilImg from "../../../../assets/images/bossil.jpg";
import bigfixImg from "../../../../assets/images/BIGFIX.png";
import dafkilitImg from "../../../../assets/images/DAFKILIT.png";
import furezaImg from "../../../../assets/images/FUREZA.png";
import krukerImg from "../../../../assets/images/KRUKER.png";
import seagullImg from "../../../../assets/images/SEAGULL.png";
import trustImg from "../../../../assets/images/TRUST.png";

const ProductCard = ({
  image,
  brand,
  name,
  code,
  moq,
  box_pack,
  isMobileView,
  cartoon_pack,
  sales_unit,
}) => {
  const navigate = useNavigate();
  const [placeholderImage, setPlaceholderImage] = useState();

  useEffect(() => {
    if (brand == "bossil") {
      setPlaceholderImage(bossilImg);
    } else if (brand == "big-fix") {
      setPlaceholderImage(bigfixImg);
    } else if (brand == "daf-kilit") {
      setPlaceholderImage(dafkilitImg);
    } else if (brand == "fuerza") {
      setPlaceholderImage(furezaImg);
    } else if (brand == "kruker") {
      setPlaceholderImage(krukerImg);
    } else if (brand == "seagull") {
      setPlaceholderImage(seagullImg);
    } else if (brand == "trust") {
      setPlaceholderImage(trustImg);
    }
  }, [brand]);

  return (
    <>
      {isMobileView ? (
        <Box
          width="100%"
          overflow="hidden"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            navigate(`/products-detail/${code}`);
          }}
          borderBottom={"2px solid #dadada"}
        >
          <Flex
            width="100%"
            justifyContent="center"
            paddingBottom={3}
            paddingTop={3}
          >
            <Flex
              width="50%"
              direction="column"
              justifyContent="center"
              alignItems="center"
              padding="5px"
            >
              <Box backgroundColor={"#f6f6f6"} borderRadius={15} padding={5}>
                <Image
                  src={image}
                  fallbackSrc={placeholderImage}
                  alt=""
                  objectFit="contain"
                  width="100%"
                  maxH="245px"
                />
              </Box>
            </Flex>

            <Flex
              width="50%"
              justifyContent="space-between"
              padding="10px"
              direction="column"
            >
              <Flex direction="column" justifyContent="space-between">
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="Roboto"
                  textOverflow="ellipsis"
                >
                  {name}
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  mt="4px"
                  fontFamily="Roboto"
                >
                  Packing: <b>{box_pack} gm/pack</b>
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  mt="4px"
                  fontFamily="Roboto"
                >
                  Sales Unit: <b>DZN / PCS</b>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Box
          width="100%"
          overflow="hidden"
          justifyContent="center"
          alignItems="center"
          padding={5}
          margin="5px"
          onClick={() => {
            navigate(`/products-detail/${code}`);
          }}
        >
          <Flex width="100%" direction="column">
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              padding="5px"
            >
              <Box backgroundColor={"#f6f6f6"} borderRadius={8} padding={5}>
                <Image
                  src={image}
                  fallbackSrc={placeholderImage}
                  alt=""
                  width="100%"
                  height={245}
                  objectFit="contain"
                  minW="240px"
                />
              </Box>
            </Flex>

            <Flex
              justifyContent="space-between"
              padding="10px"
              direction="column"
            >
              <Flex direction="column" justifyContent="space-between">
                <Text
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="Roboto"
                  textOverflow="ellipsis"
                >
                  {name}
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  mt="4px"
                  fontFamily="Roboto"
                >
                  Box Pack: <b>{box_pack ? box_pack + "/box" : "N/A"} </b>
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  mt="4px"
                  fontFamily="Roboto"
                >
                  Cartoon Pack:
                  <b>{cartoon_pack ? cartoon_pack + "/cartoon" : "N/A"} </b>
                </Text>
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  mt="4px"
                  fontFamily="Roboto"
                >
                  Sales Unit: <b>{sales_unit ? sales_unit : "N/A"} </b>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default ProductCard;
