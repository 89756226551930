import React, { useEffect, useRef } from "react";
import { Box, Flex, Image, Spacer, Text, Textarea } from "@chakra-ui/react";
import { Link,useLocation } from 'react-router-dom';
import { Table } from "../../../../components";

const ProductInfo = ({
  tableData,
  image,
  name,
  color,
  price,
  onChangeQuantity,
  onRemoveSize,
  salePrice,
  offerPrice,
  discount,
  discountPrice,
  setComments,
  productKey,
  isFocus,
  size,
  sales_unit
}) => {
  console.log(tableData);

  // const textareaReference = useRef(null);

  // useEffect(() => {
  //   if (isFocus) {
  //     textareaReference.current.focus();
  //   }
  // }, []);

  const getPriceText = () => {
    if (parseInt(offerPrice) > 0) {
      return (
        <Box w="100%">
          {discountPrice > 0 && (
            <Text fontFamily={"Roboto"} fontSize="small">
              <span
                style={{
                  textDecoration: "line-through",
                  color: "rgba(0,0,0,0.5)",
                }}
              >
                {salePrice}
              </span>
              <span style={{ color: "var(--yellow)" }}> {discount} off</span>
            </Text>
          )}
        </Box>
      );
    } else {
      return "";
    }
  };


  const constructProductURL = (color, productKey, size) => {
    const sanitizedColor = color.replace(/[%]/g, "");
    const sanitizedProductKey = productKey
    const sanitizedSize = size.replace(/[\[\]/%]+/g, "");
    const sanitizedURL = `/home?color=${sanitizedColor}&code=${sanitizedProductKey}&size=${sanitizedSize}`;
    const cleanURL = sanitizedURL.replace(/%20/g, "");
    return cleanURL;
  };
  


  const productURL = constructProductURL(color, productKey, size);
  console.log(productURL);


  return (
    <Box p="18px" m={15} borderRadius="3%" border="2px solid #DADADA">
      <Flex>
        
        <Link to={productURL}>
          <Image
            justifyContent="center"
            justifyItems="center"
            bg="#F5F5F5"
            h="50px"
            w="80px"
            src={image}
            alt="Product Image"
          />
        </Link>

        <Spacer />
        <Box pl={1}>
          <Box
            fontSize={16}
            fontWeight={700}
            fontFamily={"Roboto"}
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            w="150px"
            textOverflow={"ellipsis"}
          >
            {name}
          </Box>
          <Flex mt="5px">
            <Text fontSize={12} fontFamily={"Roboto"}>
              Color: {color||'N/A'}{" "}
            </Text>
          </Flex>
        </Box>
        <Spacer />
        {/* <Box justifyContent={"end"} w="100px">
          <Flex justifyContent={"flex-end"}>
            {" "}
            <Text fontFamily={"Roboto"}>₹ {price}</Text>
          </Flex>
          <Flex justifyContent={"flex-end"}>{getPriceText()}</Flex>
        </Box> */}
      </Flex>
      <Box mt="18px">
        <Table
          onCancel={(data) => onRemoveSize(data)}
          tableList={tableData}
          onIncrement={(data) => onChangeQuantity(data)}
          onDecrement={(data) => onChangeQuantity(data)}
        />
      </Box>

      <Textarea
        // ref={textareaReference}
        name="comment"
        placeholder="Enter your comment here"
        onChange={(event) => {
          let data = { code: productKey, color: color, comments: [] };
          tableData.forEach((td) => {
            data.comments.push({
              size: td.size,
              sales_unit: td.sales_unit,
              comment: event.target.value,
            });
          });
          setComments(data);
        }}
      />
    </Box>
  );
};

export default ProductInfo;
